import User from 'models/User';
import {find, isEmpty} from 'utils/lodash';

const state = {
  users: []
};

// getters
const getters = {
  users: state => state.users,
  get: state => id => find(state.users, user => user.id == id),
  has: state => id => !isEmpty(find(state.users, user => user.id == id))
};

const actions = {
  async all({commit}) {
    let response = await User.all();

    let data = response.data.data;

    await commit('SET_USERS', data);

    return data;
  },
  async get({
    commit
  }, user) {
    let response = await User.get(user);

    let data = response.data.data;

    await commit('ADD_USER', data);

    return data;
  }
};

// mutations
const mutations = {
  SET_USERS(state, rows) {
    state.users = rows;
  },
  ADD_USER(state, row) {
    state
      .users
      .push(row);
  },
  CLEAR_USERS(state) {
    state.users = [];
  }
};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
