<template>
    <div class="home-view">
        <Logo />
        <p class="home-text">Curated Maps.</p>
        <div v-if="!isClient">
            <router-link :to="{ name: 'dashboard' }" class="pill-btn" v-if="$auth.isAuthenticated()"
                >Dashboard</router-link
            >
            <span class="pill-btn" v-else @click="$auth.login()">Login</span>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/layout/logo';
import IntercomMixin from '@/mixins/intercom.vue';
export default {
    components: {
        Logo,
    },
    mixins: [IntercomMixin],
    computed: {
        isClient() {
            let authUser = this.$auth.getAuthUser();
            return authUser && authUser.role.name == 'Client';
        },
    },
};
</script>

<style lang="scss" scoped>
.pill-btn {
    display: block;
}
</style>
