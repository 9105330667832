<template>
    <div :class="containerClasses">
        <div class="map-panel w-full h-full">
            <mapbox
                v-if="loaded"
                ref="mapBox"
                :key="mapVersion"
                :access-token="mapBoxAccessToken"
                @map-init="onMapInit"
                @map-load="onMapLoaded"
                @map-moveend="onMapMoveEnd"
                @map-styledata="onMapStyleData"
                :map-options="options"
            />

            <destination-marker />
            <permanent-home-marker />
            <component v-if="config.provider !== 'Mapbox'" :is="config.provider" class="map-provider" />
        </div>
        <transition name="fade">
            <div class="absolute bottom-14 left-0 right-0 mx-auto flex justify-center flex-col items-center gap-2">
                <layer-detail v-if="selectedRecord" :layer="selectedRecordLayer" class="relative w-full"></layer-detail>
                <mini-detail
                    v-if="selectedRecord"
                    :record="selectedRecord"
                    :map-id="config.id"
                    :work-order-id="workOrder"
                    :allow-favoriting="allowFavoriting"
                    @annotation-updated="onAnnotationUpdated"
                    class="relative w-full"
                />
            </div>
        </transition>
        <transition name="fade">
            <display-count
                v-if="mapLoaded"
                v-show="showDisplayCount"
                class="absolute bottom-3 left-0 right-0 mx-auto"
                :display-count="totalDisplayedRecords"
                :total-count="totalPossibleRecords"
            />
        </transition>
    </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';

import { MAPBOX_ACCESS_TOKEN } from 'config';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import Apple from 'modules/provider-attribution/Apple';
import Google from 'modules/provider-attribution/Google';
import DestinationMarker from 'components/maps/markers/destination';
import PermanentHomeMarker from 'components/maps/markers/permanent-home';
import DisplayCount from 'components/maps/display-count';
import MiniDetail from 'components/maps/mini-detail';
import LayerDetail from 'components/maps/layer-detail';
import { reduce, filter, forEach, includes, debounce, isEmpty } from 'utils/lodash';
import EmitsMapPositionUpdates from 'mixins/map/emits-map-position-updates.vue';

export default {
    mixins: [EmitsMapPositionUpdates],
    components: {
        Apple,
        Google,
        Mapbox,
        DestinationMarker,
        PermanentHomeMarker,
        DisplayCount,
        LayerDetail,
        MiniDetail,
    },
    props: {
        user: {
            type: [String, Number],
            default: null,
        },
        mapId: {
            type: [String, Number],
            default: null,
        },
        workOrder: {
            type: [String, Number],
            default: null,
        },
        selectedRecord: {
            type: [Object],
            default: null,
        },
    },
    data: () => ({
        mapBoxAccessToken: MAPBOX_ACCESS_TOKEN,
        mapLoaded: false,
        popup: null,
        showDisplayCount: true,
    }),
    watch: {
        annotation: {
            deep: true,
            handler: 'onAnnotationChange',
        },
        mapBoundsComputed: {
            handler: 'onMapBoundsComputedChanged',
        },
    },
    computed: {
        ...mapGetters('map', ['config', 'style', 'layers', 'center', 'loaded', 'isStyleReady', 'isPublic']),
        ...mapGetters('annotation', ['editing', 'annotation']),
        ...mapState('records', ['dataContentRecords']),
        allowFavoriting() {
            return JSON.parse(process.env.VUE_APP_FEATURE_ENABLE_FAVORITING) && !isEmpty(this.workOrder);
        },
        containerClasses() {
            return this.isPublic ? 'w-full' : 'w-full relative';
        },
        options() {
            return {
                style: this.style,
                center: this.center,
                zoom: this.config.zoom ?? 12,
            };
        },
        mapVersion() {
            return JSON.stringify(this.options);
        },
        map() {
            return this.mapLoaded ? this.$refs.mapBox.map : null;
        },
        visibleLayers() {
            return filter(this.layers, (layer) => {
                return layer.visible;
            });
        },
        visibleLayerIds() {
            const ids = [];
            forEach(this.visibleLayers, (layer) => {
                ids.push(layer.id);
            });

            return ids;
        },
        visibleDataContentLayerRecords() {
            return filter(this.dataContentRecords, (record, index) => {
                return includes(this.visibleLayerIds, index);
            });
        },
        visibleCustomLayers() {
            return filter(this.visibleLayers, (layer) => {
                return layer.type !== 'data_content';
            });
        },
        visibleCustomLayerRecords() {
            const bounds = this.mapBoundsComputed;
            let customRecordDisplayed = 0;
            this.visibleCustomLayers.forEach(function (layer) {
                layer.annotations?.forEach(function (annotation) {
                    const coord = annotation.coordinate;
                    if (coord?.longitude && coord?.latitude) {
                        const lnglat = new mapboxgl.LngLat(coord?.longitude, coord?.latitude);
                        if (bounds.contains(lnglat)) {
                            customRecordDisplayed++;
                        }
                    }
                });
            });
            return customRecordDisplayed;
        },
        totalDisplayedRecords() {
            const dataContentRecordDisplayed = reduce(
                this.visibleDataContentLayerRecords,
                (carry, record) => {
                    return carry + record.properties.count;
                },
                0
            );

            return dataContentRecordDisplayed + this.visibleCustomLayerRecords;
        },
        totalPossibleRecords() {
            const dataContentRecordTotal = reduce(
                this.visibleDataContentLayerRecords,
                (carry, record) => carry + record.properties.totalCount,
                0
            );

            return dataContentRecordTotal + this.visibleCustomLayerRecords;
        },
        selectedRecordLayer() {
            try {
                return JSON.parse(this.selectedRecord.properties.layer);
            } catch (e) {}
            return this.selectedRecord.properties.layer;
        },
    },
    created() {
        // setup popup
        try {
            this.popup = new mapboxgl.Popup({
                closeButton: false,
                offset: [0, -35],
                closeOnClick: false,
            });
        } catch (e) {
            throw new Error(e);
        }

        this.$events.$on('map:popup:enter', this.onPopupEnter);
        this.$events.$on('map:popup:leave', this.onPopupLeave);
    },
    beforeDestroy() {
        this.$events.$off('map:popup:enter', this.onPopupEnter);
        this.$events.$off('map:popup:leave', this.onPopupLeave);
        this.clearMapCenter();
        this.clearMapBounds();
    },
    methods: {
        ...mapActions('map', ['setStyleReady', 'setChangingProvider']),
        ...mapMutations('map', {
            setMapCenter: 'SET_MAP_CENTER',
            clearMapCenter: 'CLEAR_MAP_CENTER',
            setMapBounds: 'SET_MAP_BOUNDS',
            clearMapBounds: 'CLEAR_MAP_BOUNDS',
        }),
        onMapInit() {
            this.mapLoaded = false;
            this.setStyleReady(false);
        },
        onMapLoaded() {
            this.mapLoaded = true;
            this.$events.$emit('map:loaded', this.$refs.mapBox);
            this.map.resize();
            this.setupMapPositionWatchers(this.map);
        },
        onMapMoveEnd(e) {
            this.setMapCenter(e.getCenter());
            this.setMapBounds(e.getBounds());
        },
        onMapStyleData() {
            this.popup.remove();
            if (this.mapLoaded && this.$refs.mapBox.map.getStyle() != null && !this.isStyleReady) {
                this.setStyleReady(true);
                this.$events.$emit('map:styleloaded');
                this.setChangingProvider(false);
            }
        },
        onPopupEnter({ coordinates, html, options }) {
            this.popup.options = { ...this.popup.options, ...options };
            this.popup.setLngLat(coordinates).setHTML(html).addTo(this.map);
        },
        onPopupLeave() {
            this.map.getCanvas().style.cursor = '';
            this.popup.remove();
        },
        onAnnotationChange() {
            if (this.editing && this.annotation?.coordinate?.longitude) {
                this.map.flyTo({
                    center: [this.annotation.coordinate.longitude, this.annotation.coordinate.latitude],
                    zoom: 15,
                });
            }
        },
        onMapBoundsComputedChanged() {
            this.showDisplayCount = true;
            this.hideDisplayCount();
        },
        hideDisplayCount: debounce(function () {
            this.showDisplayCount = false;
        }, 3500),
        onAnnotationUpdated() {
            this.$emit('annotation-updated');
        },
    },
};
</script>
<style>
.mapboxgl-ctrl-bottom-left {
    display: none !important;
}
</style>
