const state = {};

// getters
const getters = {};

const actions = {
  error({}, message) {
    this
      ._vm
      .$error(message);
  }
};

// mutations
const mutations = {};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
