<template>
    <div class="relocity-school template">
        <div class="main-content">
            <div class="details">
                <h3>{{ props.name }}</h3>
                <div class="address">
                    <p>{{ props.address }}</p>
                </div>

                <ul class="quick-info">
                    <li class="rating">
                        <r-icon name="star-half" icon-style="fad" />
                        {{ props.rating }}/5
                    </li>
                    <li class="grades">
                        <r-icon name="graduation-cap" icon-style="fad" />
                        Grades {{ props.grades }}
                    </li>
                    <li class="kids">
                        <r-icon name="child" icon-style="fad" />
                        {{ props.num_students }} kids
                    </li>
                </ul>
            </div>

            <div v-if="showMap" id="map-container" class="relative">
                <div id="marker" ref="marker">{{ props.name }} - {{ props.rating }}/5</div>
                <map-component v-if="false" ref="map" map-id="map" />
            </div>
        </div>
        <div class="sticky footer">
            <a :href="props.url" target="blank"> School Details </a>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import MapComponent from 'components/maps/map';

export default {
    components: {
        MapComponent,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        showMap: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        props() {
            return this.record.properties;
        },
        map() {
            return this.$refs.map.map;
        },
        mapConfig() {
            return {
                provider: 'Mapbox',
                center: {
                    latitude: this.record.geometry.coordinates[1],
                    longitude: this.record.geometry.coordinates[0],
                },
                style: 'mapbox://styles/mapbox/streets-v11',
            };
        },
    },
    created() {
        // if (this.showMap) {
        //     this.setMapConfig(this.mapConfig);
        //     this.$events.$on('map:loaded', this.setupMarker);
        // }
    },
    methods: {
        ...mapActions('map', ['setMapConfig']),
        setupMarker() {
            new mapboxgl.Marker({ element: this.$refs.marker })
                .setLngLat(this.record.geometry.coordinates)
                .addTo(this.map);
        },
    },
};
</script>

<style lang="scss">
.relocity-school.template {
    /* Details */
    .details {
        h3 {
            font-size: 24px;
            font-weight: 500;
            color: #191919;

            span {
                font-size: 14px;
            }
        }

        padding: 20px;

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }

        .quick-info {
            display: flex;
            justify-content: space-around;
            margin-top: 30px;
        }
    }

    .description {
        padding: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
    }

    #map {
        height: 200px;
    }

    #marker {
        background-color: #5d615f;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px 15px;
        border: 1px solid white;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);
            width: 0;
            border-top: 5px solid #5d615f;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }

    .sticky.footer {
        position: sticky;
        bottom: 0;
        background: white;
        box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25);
        padding: 28px;
        z-index: 10;

        a {
            margin: auto;
            text-align: center;
            background: black;
            color: white;
            display: block;
            padding: 10px;
            width: 100%;
            border-radius: 21px;
        }
    }
}
</style>
