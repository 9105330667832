import Provider from 'models/Provider';

const state = {
  providers: []
};

// getters
const getters = {
  providers: state => state.providers
};

const actions = {
  async all({ commit }) {
    let { data } = await Provider.all();

    await commit('SET_PROVIDERS', data.data);

    return data.data;
  }
};

// mutations
const mutations = {
  SET_PROVIDERS(state, rows) {
    state.providers = rows;
  },
  CLEAR_PROVIDERS(state) {
    state.providers = [];
  }
};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
