import Categories from 'models/Categories';
import { find, map } from "@/utils/lodash"

const state = {
  categories: [],
  dataContentCategories: [],
  categoriesLoaded: false
};

// getters
const getters = {
  findCategory: state => id => find(state.categories, category => parseInt(category.id) === parseInt(id)),
  findCategoryBySlug: state => slug => find(state.categories, category => category.slug === slug),
  findDataContentCategory: state => slug => find(state.dataContentCategories, ({ category }) => category.slug === slug),
  findDataContentCategoryById: state => id => find(state.dataContentCategories, ({ category }) => category.id == id),
  dataContentCategoriesList: state => map(state.dataContentCategories, ({ category }) => category)
};

const actions = {
  async all({ commit }) {
    await commit('SET_CATEGORIES_LOADED', false);

    let { data: { data }} = await Categories.all();

    await commit('SET_CATEGORIES', data);
    await commit('SET_CATEGORIES_LOADED', true);

    return data;
  },
  async get(c, slug) {
    let { data: { data }} = await Categories.get(slug);

    return data;
  },
  async getWorkOrderCategories({ commit }, workOrderId) {
    const { data: { data } } = await Categories.getWorkOrderCategories(workOrderId)

    commit('SET_DATA_CONTENT_CATEGORIES', data)

    return data
  }
};

// mutations
const mutations = {
  SET_CATEGORIES(state, rows) {
    state.categories = rows;
  },
  CLEAR_CATEGORIES(state) {
    state.categories = [];
  },
  SET_CATEGORIES_LOADED(state, value) {
    state.categoriesLoaded = value;
  },
  SET_DATA_CONTENT_CATEGORIES(state, rows) {
    state.dataContentCategories = rows;
  },
  CLEAR_DATA_CONTENT_CATEGORIES(state) {
    state.dataContentCategories = [];
  },
};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
