import store from 'store';
import { includes, sortBy } from 'utils/lodash';

const MapService = {
    addLayer,
    removeLayer,
    addSource,
    updateSource,
    removeSource,
    addClusteredFeatureCollection,
    addClusterCircleLayer,
    removeClusterCircleLayer,
    addClusterCircleTextLayer,
    updateClusterCircleLayerIcon,
    removeClusterCircleTextLayer,
    addMarkerCircleLayer,
    updateMarkerCircleLayerIcon,
    removeMarkerCircleLayer,
    addMarkerCircleFavoriteIconLayer,
    removeMarkerCircleFavoriteIconLayer,
    addMarkerCircleRecommendedIconLayer,
    removeMarkerCircleRecommendedIconLayer,
    addMarkerCircleTextLayer,
    updateMarkerCircleTextLayerIcon,
    removeMarkerCircleTextLayer,
    addMarkerFeatureState,
    removeMarkerFeatureState,
    registerMarkerEvent,
    deRegisterMarkerEvent,
    getLayerIds,
    getBottomLayerId,
    geoJsonFromLayerAnnotations,
    sourceIsLoaded,
    getBoundingBox,
};

let weightedLayers = [];
let stacking = false;

const CLUSTER_CIRCLE_SUFFIX = '_CLUSTER_CIRCLE';
const CLUSTER_CIRCLE_TEXT_SUFFIX = '_CLUSTER_CIRCLE_TEXT';
const MARKER_CIRCLE_SUFFIX = '_MARKER_CIRCLE';
const MARKER__CIRCLE_TEXT_SUFFIX = '_MARKER_CIRCLE_TEXT';
const RECOMMENDED_CIRCLE_SUFFIX = '_RECOMMENDED';
const LIKED_CIRCLE_SUFFIX = '_LIKED';

async function addLayer(layer, beforeId = null) {
    if (!store.map.getLayer(layer.id)) {
        await store.map.addLayer(layer, beforeId);
    }
}

async function removeLayer(layerId) {
    try {
        return store.map.removeLayer(layerId);
    } catch (e) {}
}

function getLayerIds() {
    const layers = store.map.getStyle().layers;

    const mapLayerSources = ['composite', 'google', 'apple'];
    const mapLayerTypes = ['background'];

    const customLayers = layers.filter((layer) => {
        return !includes(mapLayerSources, layer.source) && !includes(mapLayerTypes, layer.type);
    });

    return customLayers.map(function (layer) {
        return layer.id;
    });
}

function getBottomLayerId() {
    const layers = this.getLayerIds();
    return layers[0];
}

async function addSource(sourceId, source) {
    if (!sourceIsLoaded(sourceId)) {
        await store.map.addSource(sourceId, source);
    }
}

async function updateSource(sourceId, source) {
    if (sourceIsLoaded(sourceId)) {
        await store.map.getSource(sourceId).setData(source);
    }
}

async function removeSource(sourceId) {
    try {
        return store.map.removeSource(sourceId);
    } catch (e) {}
}

async function addClusteredFeatureCollection(sourceId, collection) {
    addSource(sourceId, {
        type: 'geojson',
        generateId: true,
        data: collection,
        clusterRadius: 50,
        cluster: true,
        clusterMaxZoom: 13,
    });
}

async function addClusterCircleLayer(sourceId, layerId, layerIcon) {
    addLayer({
        id: layerId + CLUSTER_CIRCLE_SUFFIX,
        type: 'symbol',
        source: sourceId,
        filter: ['has', 'point_count'],
        paint: {
            'text-color': '#202',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
            'icon-opacity': 0.5,
        },
        layout: {
            visibility: 'visible',
            'icon-image': layerIcon,
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
        },
    });
}

function updateClusterCircleLayerIcon(layerId, layerIcon) {
    store.map.setLayoutProperty(layerId + CLUSTER_CIRCLE_SUFFIX, 'icon-image', layerIcon);
}

async function removeClusterCircleLayer(layerId) {
    await removeLayer(layerId + CLUSTER_CIRCLE_SUFFIX);
}

async function addClusterCircleTextLayer(sourceId, layerId) {
    addLayer({
        id: layerId + CLUSTER_CIRCLE_TEXT_SUFFIX,
        type: 'symbol',
        source: sourceId,
        filter: ['has', 'point_count'],
        paint: {
            'text-color': '#000',
        },
        layout: {
            visibility: 'visible',
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 20,
            'text-anchor': 'bottom',
            'text-offset': [0, -0.6],
        },
    });
}

async function removeClusterCircleTextLayer(layerId) {
    await removeLayer(layerId + CLUSTER_CIRCLE_TEXT_SUFFIX);
}

async function addMarkerCircleLayer(sourceId, layerId, layerIcon) {
    addLayer({
        id: layerId + MARKER_CIRCLE_SUFFIX,
        type: 'symbol',
        source: sourceId,
        filter: ['!', ['has', 'point_count']],
        paint: {
            'text-color': '#202',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
        },
        layout: {
            visibility: 'visible',
            'icon-image': layerIcon,
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
        },
    });
}

async function addMarkerCircleFavoriteIconLayer(sourceId, layerId) {
    addLayer({
        id: layerId + LIKED_CIRCLE_SUFFIX,
        type: 'symbol',
        source: sourceId,
        filter: ['all', ['!', ['has', 'point_count']], ['get', 'is_liked']],
        paint: {
            'text-color': '#202',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
        },
        layout: {
            visibility: 'visible',
            'icon-image': 'relocity-favorite-icon',
            'icon-allow-overlap': false,
            'icon-anchor': 'top-right',
            'icon-offset': ['case', ['get', 'is_recommended'], ['literal', [55, -75]], ['literal', [43, -93]]],
            'icon-size': 0.5,
            'icon-ignore-placement': true,
        },
    });
}

async function removeMarkerCircleFavoriteIconLayer(layerId) {
    await removeLayer(layerId + LIKED_CIRCLE_SUFFIX);
}

async function addMarkerCircleRecommendedIconLayer(sourceId, layerId) {
    addLayer({
        id: layerId + RECOMMENDED_CIRCLE_SUFFIX,
        type: 'symbol',
        source: sourceId,
        filter: ['all', ['!', ['has', 'point_count']], ['get', 'is_recommended']],
        paint: {
            'text-color': '#202',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
        },
        layout: {
            visibility: 'visible',
            'icon-image': 'relocity-recommended-icon',
            'icon-allow-overlap': false,
            'icon-anchor': 'top-right',
            'icon-offset': [43, -93],
            'icon-size': 0.5,
            'icon-ignore-placement': true,
        },
    });
}

async function removeMarkerCircleRecommendedIconLayer(layerId) {
    await removeLayer(layerId + RECOMMENDED_CIRCLE_SUFFIX);
}

function updateMarkerCircleLayerIcon(layerId, layerIcon) {
    store.map.setLayoutProperty(layerId + MARKER_CIRCLE_SUFFIX, 'icon-image', layerIcon);
}

async function removeMarkerCircleLayer(layerId) {
    await removeLayer(layerId + MARKER_CIRCLE_SUFFIX);
}

async function addMarkerCircleTextLayer(sourceId, layerId, paint, layout) {
    const layer = {
        id: layerId + MARKER__CIRCLE_TEXT_SUFFIX,
        type: 'symbol',
        source: sourceId,
        filter: ['!', ['has', 'point_count']],
    };

    if (paint) {
        layer['paint'] = paint;
    }

    if (layout) {
        layout['text-anchor'] = 'bottom';
        layout['text-offset'] = [0, -0.6];
        layer['layout'] = layout;
    }

    addLayer(layer);
}

function updateMarkerCircleTextLayerIcon(layerId, layerIcon) {
    if (!store.map.getLayer(layerId + MARKER__CIRCLE_TEXT_SUFFIX)) {
        return;
    }
    store.map.setLayoutProperty(layerId + MARKER__CIRCLE_TEXT_SUFFIX, 'icon-image', layerIcon);
}

async function removeMarkerCircleTextLayer(layerId) {
    await removeLayer(layerId + MARKER__CIRCLE_TEXT_SUFFIX);
}

function addMarkerFeatureState(sourceId, layerId, annotationIndex) {
    store.map.setFeatureState(
        {
            source: sourceId,
            sourceLayerId: layerId + MARKER__CIRCLE_TEXT_SUFFIX,
            id: annotationIndex,
        },
        {
            editing: 1,
        }
    );
}

function geoJsonFromLayerAnnotations(layer, annotations) {
    const geoJson = { features: [] };

    for (let i in annotations) {
        const annotation = annotations[i];

        // when building an annotation this field might not be available yet
        if (annotation.coordinate == null) {
            continue;
        }

        const feature = {
            type: 'Feature',
        };

        feature.geometry = {
            type: 'Point',
            coordinates: [annotation.coordinate.longitude, annotation.coordinate.latitude],
        };

        feature.properties = {
            order: parseInt(i) + 1,
            name: annotation.name,
            place: annotation.place,
            is_liked: annotation.is_liked,
            is_recommended: annotation.is_recommended,
            description: annotation.description,
            url: annotation.url,
            url_type: annotation.url_type,
        };

        if (annotation.gallery?.id != null) {
            feature.properties.gallery = annotation.gallery;
        }

        geoJson.features.push(feature);
    }

    return geoJson;
}

function removeMarkerFeatureState(sourceId, layerId) {
    store.map.removeFeatureState({
        source: sourceId,
        sourceLayerId: layerId + MARKER__CIRCLE_TEXT_SUFFIX,
    });
}

function registerMarkerEvent(event, layerId, callback) {
    store.map.on(event, layerId + MARKER_CIRCLE_SUFFIX, callback);
}

function deRegisterMarkerEvent(event, layerId) {
    store.map.off(event, layerId + MARKER_CIRCLE_SUFFIX);
}

function sourceIsLoaded(sourceId) {
    return store.map.getSource(sourceId) != null;
}

// have the layers show up in the correct order
// eslint-disable-next-line
async function stackLayers() {
    if (stacking) {
        return;
    }
    stacking = true;
    await cleanLayers();

    let sorted = sortBy(weightedLayers, 'weight');

    for (var i = sorted.length - 1; i >= 0; i--) {
        i = parseInt(i); // i is getting casted into a string (?)

        let layer = sorted[i].layer;
        let nextLayer_id = i == sorted.length - 1 ? null : sorted[i + 1].layer.id;
        await store.map.moveLayer(layer.id, nextLayer_id);
    }
    stacking = false;
}

// remove any layers that the user may have deleted from the map
async function cleanLayers() {
    weightedLayers = weightedLayers.filter(async function (weightedLayer) {
        return await store.map.getLayer(weightedLayer.layer.id);
    });
}

// return the southwest and northeast bounds of the map
function getBoundingBox(map) {
    const bounds = map.getBounds();
    return {
        southWest: {
            lat: bounds._sw.lat,
            lng: bounds._sw.lng,
        },
        northEast: {
            lat: bounds._ne.lat,
            lng: bounds._ne.lng,
        },
    };
}
export default MapService;
