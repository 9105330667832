<template>
    <router-view id="vue" />
</template>

<script>
export default {
    name: 'App',
    metaInfo: {
        title: 'Maps',
        titleTemplate: '%s | Relocity',
    },
};
</script>

<style lang="scss">
@import 'scss/app';
</style>
