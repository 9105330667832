import Vue from 'vue';
import { get } from "@/utils/lodash"

const state = {};
const vue = new Vue();

// getters
const getters = {
  user: () => vue.$auth.getAuthUser(),
  id: () => get(vue.$auth.getAuthUser(), 'id'),
  name: () => get(vue.$auth.getAuthUser(), 'first_name') + ' ' + get(vue.$auth.getAuthUser(), 'last_name'),
  isAdmin: () => {
    return vue.$auth.userIsRole(['Admin', 'Super Admin']);
  },
  isSuperAdmin: () => {
    return vue.$auth.userIsRole(['Super Admin']);
  },
  isAOM: () => {
    return vue.$auth.userIsRole(['Area Ops Manager']);
  }
};

const actions = {};

// mutations
const mutations = {};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
