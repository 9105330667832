import Records from 'models/Records';
import { map, forEach } from 'utils/lodash';

const state = {
  dataContentRecords: {}
};

// getters
const getters = {};

const actions = {
  async search (c, payload) {
    let { data } = await Records.search(payload);

    return data;
  },
  async searchUrl({ commit }, { url, layerId }) {
    let { data } = await Records.getUrlData(url)

    await commit('SET_DATA_CONTENT_RECORDS', { data, layerId })

    return data
  },
  async searchDataContent ({ commit }, payload) {
    let { data } = await Records.search(payload);

    const layerId = payload.layerId;

    await commit('SET_DATA_CONTENT_RECORDS', { data, layerId });
  },
  async syncDataContentFavorites({ commit, state }, { layerId, favoriteAnnotations, destructive }) {
    let { features, properties, type } = state.dataContentRecords[layerId]
    features = map(features, (feature) => {
      forEach(favoriteAnnotations, (favoriteAnnotation) => {
        if (feature.id === favoriteAnnotation.data_content_record_id) {
          feature.properties.is_liked = favoriteAnnotation.is_liked
          feature.properties.is_recommended = favoriteAnnotation.is_recommended
          if (favoriteAnnotation.id) {
            feature.properties.platform_id = favoriteAnnotation.id
          } else {
            delete feature.properties.platform_id
          }
          return false
        }

        if (destructive) {
          feature.properties.is_liked = false
          feature.properties.is_recommended = false
        }
      })
      return feature
    })

    await commit('SET_DATA_CONTENT_RECORDS', { data: { features, properties, type }, layerId })
  },
  async get (c, payload) {
    let { data } = await Records.get(payload);

    return data.data;
  },
  async getGeoJSON (c, payload) {
    let { data } = await Records.getGeoJSON(payload);

    return data;
  }
};

// mutations
const mutations = {
  SET_DATA_CONTENT_RECORDS (state, { data, layerId }) {
    const newDataContentRecords = { ...state.dataContentRecords };
    newDataContentRecords[layerId] = data;
    state.dataContentRecords = newDataContentRecords;
  },
  CLEAR_DATA_CONTENT_RECORDS (state) {
    state.dataContentRecords = {}
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
