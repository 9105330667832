<template>
    <select :id="id" :name="name" :class="classes" :value="value" @change="onChange">
        <option v-if="empty" :value="null" v-text="placeholder" />
        <template v-if="optionGroupLabel && optionGroupChildren">
            <optgroup v-for="optgroup in options" :key="optgroup.code" :label="optgroup.label">
                <option
                    v-for="option in optgroup[optionGroupChildren]"
                    :key="option[trackBy]"
                    :value="option[trackBy]"
                    v-text="option[label]"
                />
            </optgroup>
        </template>
        <template v-else>
            <option v-for="option in options" :key="option[trackBy]" :value="option[trackBy]" v-text="option[label]" />
        </template>
    </select>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        classes: {
            type: [String, Array, Object],
        },
        value: {
            type: [String, Number],
        },
        optionGroupLabel: {
            type: String,
            default: null,
        },
        optionGroupChildren: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'title',
        },
        trackBy: {
            type: String,
            default: 'key',
        },
        options: {
            type: [Array, Object],
            required: true,
        },
        empty: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '-- Select --',
        },
    },
    methods: {
        onChange(event) {
            this.$emit('change', event.target.value);
        },
    },
};
</script>
