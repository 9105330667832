import axios from 'services/axios';

let authUserKey = 'relocity_auth_user';

async function getProfile() {
    return await axios.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/profile');
}

function getAuthUser() {
    return JSON.parse(localStorage.getItem(authUserKey));
}

export default {
    install: function (Vue) {
        Vue.prototype.$auth.getAuthUser = function () {
            return getAuthUser();
        };

        Vue.prototype.$auth.userIsRole = function (role) {
            if (getAuthUser() == null) {
                return false;
            }

            if (Array.isArray(role)) {
                return role.indexOf(getAuthUser().role.name) > -1;
            }

            return getAuthUser().role.name === role;
        };

        Vue.prototype.$auth.onAuthenticated = async function (accessToken) {
            let bearer = 'Bearer ' + accessToken;

            axios.defaults.headers.common.Authorization = bearer;

            let response = await getProfile();
            let authUser = response.data.data;

            localStorage.setItem(authUserKey, JSON.stringify(authUser));
        };

        Vue.prototype.$auth.onLogout = function () {
            localStorage.clear();
        };
    },
};
