<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'contained',
            validator: function (value) {
                return ['outlined', 'contained'].indexOf(value) !== -1;
            },
        },
    },
};
</script>
