import http from 'services/axios';

class Categories {
    static all() {
        return http.get(process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL + '/api/v1/layer/categories');
    }

    static get(slug) {
        return http.get(process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL + `/api/v1/layer/categories/${slug}`);
    }

    static getWorkOrderCategories(workOrderId) {
        return http.get(
            process.env.VUE_APP_RELOCITY_PLATFORM_URL +
                `/api/v1/work-orders/${workOrderId}/maps/data-content-categories`
        );
    }
}

export default Categories;
