import Vue from 'vue';
import { merge } from 'utils/lodash';
import Toasted from 'vue-toasted';

Vue.use(Toasted, { iconPack: 'fontawesome' });

const options = {
    duration: 5000,
    position: 'bottom-center',
    keepOnHover: true,
    theme: 'bubble',
    action: [],
};

Vue.prototype.$success = function (message) {
    this.$toasted.success(
        message,
        merge(options, {
            icon: {
                name: 'check',
                after: true,
            },
        })
    );
};

Vue.prototype.$warning = function (message) {
    this.$toasted.info(
        message,
        merge(options, {
            icon: {
                name: 'check',
                after: true,
            },
        })
    );
};

Vue.prototype.$error = function (message, opts = {}) {
    if (opts.sticky) {
        options.duration = 1000 * 60 * 5;
        options.action.push({
            text: 'Dismiss',
            onClick: (e, toastObject) => {
                toastObject.goAway(0);
            },
        });
    }
    this.$toasted.error(
        message,
        merge(options, {
            icon: {
                name: 'times',
                after: true,
            },
        })
    );
};
