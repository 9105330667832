import Role from 'models/Role';
import {ALLOWED_ROLES} from 'config';
import {filter, includes, find} from 'utils/lodash';

const state = {
  roles: []
};

// getters
const getters = {
  roles: (state, getters, rootState, rootGetters) => filter(state.roles, role => {
    if (rootGetters['auth/isAdmin']) {
      return includes(ALLOWED_ROLES, role.name);
    }

    return includes(['Host'], role.name);
  }),
  findById: state => id => find(state.roles, role => role.id == id)
};

const actions = {
  async all({commit}) {
    let response = await Role.all();

    let data = response.data.data;

    await commit('SET_USER_ROLES', data);

    return data;
  }
};

// mutations
const mutations = {
  SET_USER_ROLES(state, rows) {
    state.roles = rows;
  },
  CLEAR_USER_ROLES(state) {
    state.roles = [];
  }
};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
