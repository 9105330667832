import platform from 'services/api/platform';

const state = {
    toDelete: []
};

// getters
const getters = {};

const actions = {
    async get({}, {gallery}) {
        let response = await platform.get(`galleries/${gallery}`);

        let data = response.data.data;

        return data;
    },
    async create({}, payload) {
        let response = await platform.post(`galleries`, payload);

        let data = response.data.data;

        return data;
    },
    async update({}, {gallery}) {
        let response = await platform.patch(`galleries/${gallery}`);

        let data = response.data.data;

        return data;
    },
    async sort({}, {gallery, ids}) {
        let response = await platform.post(`galleries/${gallery}/order`, {media_ids: ids});

        let data = response.data.data;

        return data;
    },
    async delete({}, {gallery}) {
        let response = await platform.delete(`galleries/${gallery}`);

        let data = response.data.data;

        return data;
    },
    flagToDelete({
        commit
    }, gallery) {
        return commit('FLAG_TO_DELETE', gallery);
    },
    deleteFlaggedGalleries({commit, dispatch}) {
        state
            .toDelete
            .forEach(function (deleteMe) {
                dispatch('delete', deleteMe);
            });
        return commit('RESET_FLAG_TO_DELETE');
    }
};

// mutations
const mutations = {
    FLAG_TO_DELETE(state, gallery) {
        state
            .toDelete
            .push(gallery);
    },
    RESET_FLAG_TO_DELETE(state) {
        state.toDelete = [];
    }
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
};
