import http from 'services/axios';
import {map} from 'utils/lodash';
import {DATA_API_URL, DATA_API_KEY, MAP_PROVIDERS} from 'config';

const state = {
  tempHousingProviders: [],
  mapProviders: MAP_PROVIDERS
};

// getters
const getters = {
  tempHousingProviders: state => state.tempHousingProviders,
  mapProviders: state => map(state.mapProviders, provider => {
    return {key: provider, title: provider};
  })
};

const actions = {
  async getTempHousingProviders({commit}) {
    let response = await http.get(DATA_API_URL + '/api/v1/temp-housing-providers', {
      params: {
        api_key: DATA_API_KEY
      }
    });

    let data = response.data.data;

    await commit('SET_TEMP_HOUSING_PROVIDERS', data);

    return data;
  }
};

// mutations
const mutations = {
  SET_TEMP_HOUSING_PROVIDERS(state, rows) {
    state.tempHousingProviders = rows;
  },
  CLEAR_TEMP_HOUSING_PROVIDERS(state) {
    state.tempHousingProviders = [];
  }
};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
