import Vue from 'vue';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
    /*
  These are the correct breakpoints
  if enabled it breaks some mobile renderings since
  mobile in the code hard codes the breakpoints at md which is set to
  767
  breakpoints: {
      xs: 576,
      sm: 768,
      md: 767,
      lg: 1399,
      xl: Infinity
  }
*/
    breakpoints: {
        md: 767,
        lg: Infinity,
    },
});
