import Vue from 'vue';
import { linkify } from 'utils/helpers';

Vue.directive('linkify', {
    inserted: function (el, binding) {
        el.innerHTML = linkify(el.innerHTML, {
            target: '_blank',
            ...binding?.value,
        });
    },
    update: function (el, binding) {
        el.innerHTML = linkify(el.innerHTML, {
            target: '_blank',
            ...binding?.value,
        });
    },
});
