import { indexOf, find, startsWith, map } from 'utils/lodash';
import linkifyHtml from 'linkifyjs/html';
import { CDN_URL } from 'config';

export function replace(collection, identity, replacement) {
    let index = indexOf(collection, find(collection, identity));
    collection.splice(index, 1, replacement);
}

export function modifyFeatures(features, values) {
    return map(features, (feature) => {
        feature.properties.record_id = feature.id;
        return {
            ...feature,
            properties: {
                ...feature.properties,
                ...values,
            },
        };
    });
}

export function distance(lat1, lon1, lat2, lon2, unit = 'M') {
    if (lat1 == lat2 && lon1 == lon2) {
        return 0;
    }

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;

    let distance = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (distance > 1) {
        distance = 1;
    }

    distance = Math.acos(distance);
    distance = (distance * 180) / Math.PI;
    distance = distance * 60 * 1.1515;

    if (unit === 'K') {
        distance = distance * 1.609344;
    } else if (unit === 'N') {
        distance = distance * 0.8684;
    }

    return Number(distance).toFixed(2);
}

// get a field value for the data returned from the server
export function dataContentFieldValue(data, name) {
    return data.values.find((f) => f.title == name)?.value;
}

export function icon(icon) {
    return `${CDN_URL}/assets/map-icons/${icon}.svg`;
}

export function getExtension(filename) {
    return filename.split('.').pop();
}

export function assetType(type) {
    if (startsWith(type, 'video')) {
        return 'videos';
    }

    return 'photos';
}

export function linkify(string, options) {
    return linkifyHtml(string, options);
}
