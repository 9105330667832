import http from 'services/axios';

class Annotation {
    static update({ workOrderId, mapId, layerId, annotationId, payload }) {
        return http.patch(
            process.env.VUE_APP_RELOCITY_PLATFORM_URL +
                `/api/v1/work-orders/${workOrderId}/map/${mapId}/layers/${layerId}/annotations/${annotationId}`,
            payload
        );
    }
    static store({ workOrderId, mapId, layerId, payload }) {
        return http.post(
            process.env.VUE_APP_RELOCITY_PLATFORM_URL +
                `/api/v1/work-orders/${workOrderId}/map/${mapId}/layers/${layerId}/annotations`,
            payload
        );
    }
    static destroy({ workOrderId, mapId, layerId, annotationId }) {
        return http.delete(
            process.env.VUE_APP_RELOCITY_PLATFORM_URL +
                `/api/v1/work-orders/${workOrderId}/map/${mapId}/layers/${layerId}/annotations/${annotationId}`
        );
    }
}

export default Annotation;
