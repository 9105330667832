import Vue from 'vue';

function toggleDisabled(el, value) {
    if (value) {
        el.setAttribute('disabled', true);
        el.classList.add('disabled');
    } else {
        el.removeAttribute('disabled');
        el.classList.remove('disabled');
    }
}

Vue.directive('disabled', {
    bind: function (el, binding) {
        toggleDisabled(el, binding.value);
    },
    update: function (el, binding) {
        toggleDisabled(el, binding.value);
    },
});
