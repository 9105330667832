<template>
    <div class="relocity-schools template">
        <div class="main-content">
            <header class="p-2" ref="schoolInfoPanel">
                <div class="font-3 primary-text-color">School</div>
                <div class="font-1 primary-text-color">
                    {{ rowProperty('properties.sg-schools-name-of-school', 'N/A') }}
                </div>
                <div class="font-3 secondary-text-color">
                    {{ rowProperty('properties.sg-schools-address', 'N/A') }}
                </div>
            </header>

            <div>
                <!-- overlay starts-->
                <div class="w-full p-1 mt-0 md:mt-16 mb-2 md:mb-20">
                    <div class="h-64 flex justify-center items-center" ref="mapContainer">
                        <map-image
                            class="rounded-lg"
                            :longitude="coordinates[0]"
                            :latitude="coordinates[1]"
                            :zoom="13"
                            :height="height"
                            :width="width"
                            makiIcon="school"
                            iconColor="23B899"
                        />
                    </div>
                </div>
                <!-- overlay ends -->
            </div>
            <div class="details bg-black min-h-screen">
                <div class="flex flex-row justify-center mb-8">
                    <div class="flex flex-row w-32 justify-evenly">
                        <a class="icon-wrapper" :href="contactEmail" v-if="contactEmail">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-size"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.Ï997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                        </a>
                        <a class="icon-wrapper" :href="contactPhone" v-if="contactPhone">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-size"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                                />
                            </svg>
                        </a>
                        <a class="icon-wrapper" :href="website" v-if="website">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-size"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>
                    </div>
                </div>

                <!-- grid start -->
                <div class="grid grid-cols-2 gap-x-40">
                    <!-- row 1 -->
                    <hr class="border-gray-700 col-span-2" />

                    <!-- row 2 -->
                    <div v-if="curriculum">
                        <div class="font-3 secondary-text-color">Curriculum</div>
                        <div class="font-2 accent-text-color">
                            {{ curriculum }}
                        </div>
                    </div>

                    <!-- row 3 -->
                    <div v-if="studentBody">
                        <div class="font-3 secondary-text-color">Student Body</div>
                        <div class="font-2 accent-text-color">{{ studentBody }}</div>
                    </div>

                    <div v-if="yearFounded">
                        <div class="font-3 secondary-text-color">Year Founded</div>
                        <div class="font-2 accent-text-color">
                            {{ yearFounded }}
                        </div>
                    </div>

                    <!-- row 4 -->
                    <div v-if="gradeLevel">
                        <div class="font-3 secondary-text-color">Grade Level</div>
                        <div class="font-2 accent-text-color">
                            {{ gradeLevel }}
                        </div>
                    </div>

                    <div v-if="academicYear">
                        <div class="font-3 secondary-text-color">Academic Year</div>
                        <div class="font-2 accent-text-color">
                            {{ academicYear }}
                        </div>
                    </div>

                    <!-- row 5 -->
                    <hr class="border-gray-700 col-span-2" />

                    <!-- row 6 -->
                    <div class="col-span-2" v-if="contactEmail">
                        <div class="font-3 accent-text-color">Contact Email</div>
                        <div class="font-2 accent-text-color">
                            {{ rowProperty('properties.sg-schools-contact-email') }}
                        </div>
                    </div>

                    <!-- col span 7 -->
                    <div class="col-span-2" v-if="website">
                        <div class="font-3 accent-text-color">Website</div>
                        <a
                            :href="website"
                            class="font-2 accent-text-color underline"
                            style="font-style: italic"
                            target="blank"
                        >
                            {{ rowProperty('properties.sg-schools-website', 'N/A') }}
                        </a>
                    </div>
                </div>
                <!-- grid end -->
            </div>
        </div>
    </div>
</template>

<script>
import { layerDetails } from 'mixins';
import MapImage from '@/components/ui/images/map-image';

export default {
    mixins: [layerDetails],
    components: {
        MapImage,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        showMap: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        width: null,
        height: null,
        navHeight: null,
        schoolInfoPanelSize: null,
        mapContainerTop: 0,
    }),
    mounted() {
        const box = this.$refs.mapContainer;
        const boxSize = box.getBoundingClientRect();
        this.width = Math.max(1, Math.min(Math.trunc(boxSize.width), 1280));
        this.height = Math.max(1, Math.min(Math.trunc(boxSize.height), 1280));
    },
    computed: {
        row() {
            return this.record;
        },
        props() {
            return this.record.properties;
        },
        coordinates() {
            return this.row.geometry.coordinates;
        },
        curriculum() {
            return this.getPropOrFalse('sg-schools-curriculum');
        },
        studentBody() {
            return this.getPropOrFalse('sg-schools-student-body');
        },
        yearFounded() {
            return this.getPropOrFalse('sg-schools-year-founded');
        },
        gradeLevel() {
            return this.getPropOrFalse('sg-schools-grade-level');
        },
        academicYear() {
            return this.getPropOrFalse('sg-schools-academic-calendar-year');
        },
        contactEmail() {
            const email = this.getPropOrFalse('sg-schools-contact-email');
            return email ? `mailto:${email}` : false;
        },
        website() {
            const website = this.getPropOrFalse('sg-schools-website');
            if (website) {
                return website.startsWith('http') ? website : `https://${website}`;
            }
            return false;
        },
        e164Phone() {
            const e164 = String(this.getPropOrFalse('sg-schools-details-contact-phone'));
            if (e164 !== 'false') {
                return e164.startsWith('+') ? e164 : `+${e164}`;
            }

            return false;
        },
        contactPhone() {
            return this.e164Phone ? `tel:${this.e164Phone}` : false;
        },
    },
    methods: {
        getPropOrFalse(property) {
            const propertyPath = 'properties.' + property;
            return this.rowProperty(propertyPath, null) === null ? false : this.rowProperty(propertyPath);
        },
    },
};
</script>
<style lang="scss">
.relocity-schools.template {
    .force-no-mb {
        margin-bottom: 0px !important;
    }

    /* Header */
    header {
        min-height: 40px;
        padding: 20px;
    }

    /* Details */
    .details {
        padding: 20px;
        cursor: default !important;
    }

    .icon-wrapper {
        border-radius: 9999px;
        height: 1.75rem;
        width: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #23b899;
    }

    .icon-size {
        height: 1rem;
        width: 1rem;
    }

    .font-1 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 1.2rem;
    }

    .font-2 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
    }

    .font-3 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .primary-text-color {
        color: #505152;
    }

    .secondary-text-color {
        color: #9b9b9b;
    }

    .accent-text-color {
        color: #fff;
    }

    .over {
        top: -45%;
    }

    // half black half white
    .hbhw {
        background-image: linear-gradient(to top, #000000 0%, #000000 50%, #ffffff 50%, #ffffff 100%);
    }
}
</style>
