import {filter, get, cloneDeep, sortBy, isEmpty} from 'utils/lodash';

const state = {
    types: [
        {
            title: 'Commute Radius',
            key: 'commute_radius',
            visible: true
        }, {
            title: 'Custom',
            key: 'custom',
            visible: true
        },
        {
            title: 'Area Tour',
            key: 'area_tour',
            visible: true
        }, {
            title: 'Temp Housing',
            key: 'temp_housing',
            visible: process.env.VUE_APP_TEMP_HOUSING
        }
    ],
    configs: {
        commute_radius: {
            filters: {
                profile: 'driving',
                contours_minutes: [
                    '15', '30', '45'
                ],
                contours_colors: ['FFFFFF', 'FFFFFF', 'D0021B']
            }
        },
        custom: {
            icon: 'relocity-custom-marker'
        },
        area_tour: {
            icon: 'relocity-brown-marker'
        },
        transit: {
            filters: {
                radius: '5'
            }
        },
        temp_housing: {
            provider_id: null,
            filters: {
                from_date: null,
                to_date: null,
                reference_number: null,
                radius: 15,
                num_bedrooms: 'All',
                selected_filters: [],
                locked: []
            }
        }
    },
    layerContents: JSON.parse(localStorage.getItem('LAYER_COPY')),
    editing: null
};

// getters
const getters = {
    types: (state) => sortBy(filter(state.types, (type) => type.visible !== 'false'), 'title'),
    configs: (state) => state.configs,
    getConfig: (state) => (key) => cloneDeep(get(state.configs, key)),
    layerContents: (state) => state.layerContents,
    hasCopy: (state) => !isEmpty(state.layerContents),
    editing: (state) => state.editing,
    isEditing: (state) => !isEmpty(state.editing),
    isEditingLayer: (state) => (id) => get(state.editing, 'id') == id
};

const actions = {
    async copyLayer({
        commit
    }, payload) {
        let annotations = payload.annotations;

        let layer = {
            ...payload,
            annotations: annotations
        };

        await commit('SET_COPY_LAYER_CONTENTS', {
            ...layer,
            name: `${payload.name} - Cloned`
        });
    },
    async clearLayer({commit}) {
        await commit('CLEAR_COPY_LAYER_CONTENTS');
    },
    setEditing({
        commit
    }, layer) {
        commit('SET_EDITING', layer);
    },
    clearEditing({commit}) {
        commit('CLEAR_EDITING');
    }
};

// mutations
const mutations = {
    SET_COPY_LAYER_CONTENTS(state, payload) {
        Object.assign(payload, {order: 0});
        state.layerContents = payload;
        localStorage.setItem('LAYER_COPY', JSON.stringify(payload));
    },
    CLEAR_COPY_LAYER_CONTENTS(state) {
        state.layerContents = {};
        localStorage.removeItem('LAYER_COPY');
    },
    SET_EDITING(state, layer) {
        state.editing = layer;
    },
    CLEAR_EDITING(state) {
        state.editing = null;
    }
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
};
