<template>
    <div class="relocity-shuttle-stop template">
        <div class="main-content">
            <div class="details">
                <p class="text-xs mb-2">Shuttle Stop</p>
                <h3 class="name mb-3">
                    {{ props['shuttle-stops-details-name'] }}
                </h3>

                <div class="address">
                    <p class="text-xs">{{ props['shuttle-stops-details-address'] }}</p>
                </div>
            </div>

            <hr />

            <div class="description">
                <p>{{ props['shuttle-stops-details-description'] }}</p>
            </div>

            <div class="map relative">
                <div id="map-container" class="relative">
                    <div class="hidden">
                        <div id="marker" ref="marker">
                            <r-icon name="shuttle-van" icon-style="fad" size="2x" color="#23B899" />
                        </div>
                    </div>
                    <map-component v-if="false" ref="map" map-id="map" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import MapComponent from 'components/maps/map';

export default {
    components: {
        MapComponent,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    computed: {
        props() {
            return this.record.properties;
        },
        map() {
            return this.$refs.map.map;
        },
        mapConfig() {
            return {
                provider: 'Mapbox',
                center: {
                    latitude: this.record.geometry.coordinates[1],
                    longitude: this.record.geometry.coordinates[0],
                },
                style: 'mapbox://styles/mapbox/streets-v11',
                destination_title: this.props['shuttle-stops-details-name'],
                zoom: 14,
            };
        },
    },
    created() {
        // this.setMapConfig(this.mapConfig);
        // this.$events.$on('map:loaded', this.setupMarker);
    },
    methods: {
        ...mapActions('map', ['setMapConfig']),
        setupMarker() {
            new mapboxgl.Marker({ element: this.$refs.marker })
                .setLngLat(this.record.geometry.coordinates)
                .addTo(this.map);
        },
    },
};
</script>

<style lang="scss">
.relocity-shuttle-stop.template {
    /* Details */
    .details {
        padding: 10px;

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }
    }

    .description {
        padding: 10px;
        font-size: 14px;
        line-height: 22px;
        color: #9b9b9b;
    }

    #map {
        height: 200px;

        i.fa-shuttle-van {
            --fa-secondary-color: dimgray;
        }
    }
}
</style>
