import http from 'services/axios';

class Map {
    static all(user, page) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + user + '/maps', {
            params: {
                page: page,
            },
        });
    }

    static templates() {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/maps/templates', {
            params: {
                per_page: 'all',
            },
        });
    }

    static export(user, map) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + `/api/v1/users/${user}/maps/${map}/export`);
    }

    static import(user, map, payload) {
        return http.post(
            process.env.VUE_APP_RELOCITY_PLATFORM_URL + `/api/v1/users/${user}/maps/${map}/import`,
            payload
        );
    }

    static search(user, keyword) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + user + '/maps', {
            params: {
                query: keyword,
            },
        });
    }

    static getWorkOrderMap(workOrder) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/work-orders/' + workOrder + '/map');
    }

    static updateWorkOrderMap(workOrder, config) {
        config.layers = config.layers.map(function (layer) {
            delete layer.setup;
            delete layer.geoJson;
            delete layer.url;
            return layer;
        });

        return http.patch(
            process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/work-orders/' + workOrder + '/map',
            config
        );
    }

    static get(user, map) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + user + '/maps/' + map);
    }

    static published(map) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/maps/' + map + '/published');
    }

    static create(user, config) {
        return http.post(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + user + '/maps', config);
    }

    static update(user, map, config) {
        config.layers = config.layers.map(function (layer) {
            delete layer.setup;
            delete layer.geoJson;
            delete layer.url;
            return layer;
        });

        return http.patch(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + user + '/maps/' + map, config);
    }

    static delete(user, map) {
        return http.delete(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + user + '/maps/' + map);
    }
}

export default Map;
