import axios from 'axios';
import { PLATFORM_API_URL, DATA_API_KEY } from 'config';

const http = axios.create({
    baseURL: PLATFORM_API_URL + '/api/v1/',
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    },
    params: {
        api_key: DATA_API_KEY,
    },
});

http.interceptors.request.use(
    function (config) {
        let token = localStorage.relocity_access_token;
        if (token !== null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

export default http;
