<template>
    <div>
        <div v-if="visible" class="spinner">
            <r-icon icon-style="fal" name="spinner-third" size="2x" spinner pulse />
        </div>
        <slot v-else />
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>
.spinner {
    color: #9b9b9b;
    text-align: center;
    margin: 1em;
    cursor: wait;
}
</style>
