<template>
    <div class="max-w-md w-auto p-4 bg-white z-10 rounded text-center text-sm py-2">Layer: {{ layer.name }}</div>
</template>
<script>
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
};
</script>
