<script>
import { isEmpty } from '@/utils/lodash';
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('auth', {
            intercomUser: 'user',
            intercomUserId: 'id',
            intercomUserName: 'name',
        }),
        intercomOptions() {
            return {
                app_id: process.env.VUE_APP_INTERCOM_APP_ID,
                email: this.intercomUser.email,
                name: this.intercomUserName,
                user_hash: this.intercomUser.intercom_user_hashes.web,
                user_id: this.intercomUserId,
                created_at: this.intercomUser.created_at,
                company: {
                    company_id: this.intercomUser.company?.id,
                    name: this.intercomUser.company?.name,
                },
            };
        },
    },
    watch: {
        intercomUserId: {
            immediate: true,
            handler: 'onUserChange',
        },
    },
    beforeDestroy() {
        this.onShutdownIntercom();
    },
    methods: {
        onUserChange() {
            if (!isEmpty(this.intercomUserId)) {
                this.onBootIntercom();
            }
        },
        onBootIntercom() {
            window.Intercom && window.Intercom('boot', this.intercomOptions);
        },
        onShutdownIntercom() {
            window.Intercom && window.Intercom('hide');
        },
    },
};
</script>
