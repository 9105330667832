import { forEach, findIndex } from 'utils/lodash';

const state = {
  allProperties: [],
  selectedProperties: [],
};

const mutations = {
  addSelectedProperty(state, property) {
    state.selectedProperties.push(property);
  },
  removeSelectedProperty(state, property) {
    const currentSelectedProperties = [...state.selectedProperties];
    state.selectedProperties = [];
    forEach(currentSelectedProperties, selectedProperty => {
      if (selectedProperty.properties.id === property.properties.id) {
        if (selectedProperty.properties.type !== property.properties.type)
          state.selectedProperties.push(selectedProperty);
      } else {
        state.selectedProperties.push(selectedProperty);
      }
    });
  },
  updateProperty(state, property) {
    let index = findIndex(state.allProperties, stateProperty => {
      return (
        stateProperty.properties.id === property.properties.id &&
        stateProperty.properties.type === property.properties.type
      );
    });
    state.allProperties[index] = property;
  },
  selectedProperties(state, properties) {
    state.selectedProperties = properties;
  },
  allProperties(state, properties) {
    state.allProperties = properties;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
