<script>
import { debounce } from 'utils/lodash';

import MapService from 'services/mapservice';
export default {
    data: () => ({
        // map.getBounds() does not update in computed values,
        // so this object can be used for that purpose
        mapBoundsComputed: false,
    }),
    methods: {
        setupMapPositionWatchers(map) {
            map.on('moveend', this.onMapPositionChange);
            this.mapBoundsComputed = map.getBounds();
        },
        onMapPositionChange: debounce(function (event) {
            const map = event.target;
            this.mapBoundsComputed = map.getBounds();
            this.$events.$emit('map:position:update', {
                map: map,
                boundingBox: MapService.getBoundingBox(map),
            });
        }, 750),
    },
};
</script>
