const MAPBOX_ACCESS_TOKEN = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
const DATA_API_URL = process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL;
const DATA_API_KEY = process.env.VUE_APP_RELOCITY_DATA_CONTENT_API_KEY;
const PLATFORM_API_URL = process.env.VUE_APP_RELOCITY_PLATFORM_URL;

const ALLOWED_ROLES = ['Super Admin', 'Admin', 'Area Ops Manager', 'Host'];
const MAP_PROVIDERS = ['Apple', 'Google', 'Mapbox'];
const VIDEO_TYPES = [
    { key: 'standard', title: 'Standard' },
    { key: 'spherical', title: '360 Video' },
];
const COMMUTE_TYPES = ['driving', 'walking', 'cycling'];

const CDN_URL = 'https://cdn.relocity.com';

export {
    CDN_URL,
    VIDEO_TYPES,
    DATA_API_URL,
    DATA_API_KEY,
    ALLOWED_ROLES,
    MAP_PROVIDERS,
    COMMUTE_TYPES,
    PLATFORM_API_URL,
    MAPBOX_ACCESS_TOKEN,
};
