import Vue from 'vue';

import {
    Button,
    Checkbox,
    Input,
    Modal,
    Icon,
    Label,
    InputGroup,
    Help,
    Textarea,
    Switch,
    Select,
    Tab,
    Tabs,
} from '@relocity/ui';

// import Input from 'components/ui/forms/input';
Vue.component('r-input', Input);
Vue.component('r-label', Label);
Vue.component('r-textarea', Textarea);
Vue.component('r-switch', Switch);
Vue.component('r-checkbox', Checkbox);
Vue.component('r-help', Help);
Vue.component('r-input-group', InputGroup);

// import TextArea from 'components/ui/forms/textarea';
Vue.component('r-textarea', Textarea);

Vue.component('r-button', Button);

import LegacySelect from 'components/ui/forms/select';
Vue.component('r-select', LegacySelect);
Vue.component('r-ui-select', Select);

// import Icon from 'components/ui/icons/icon';
Vue.component('r-icon', Icon);

import Editable from 'components/ui/editable/editable';
Vue.component('r-editable', Editable);

/**
 * Due to a bug in the way this component loads it's state when loaded in async mode
 * we have to load it in in sync, this will trigger an update to the
 * display data property when it's destroyed and re-created
 */
import AutoComplete from 'components/ui/autocomplete/autocomplete';
Vue.component('r-autocomplete', AutoComplete);

const DatePicker = () =>
    import(
        /* webpackChunkName:
"datepicker" */
        'components/ui/forms/datepicker'
    );
Vue.component('r-date-picker', DatePicker);

const DateTimePicker = () =>
    import(
        /* webpackChunkName:
"datetimepicker" */
        'components/ui/forms/date'
    );
Vue.component('r-datetime-picker', DateTimePicker);

const RangeSlider = () =>
    import(
        /* webpackChunkName:
"range-slider" */
        'components/ui/slider/rangeslider'
    );
Vue.component('r-range-slider', RangeSlider);

const TinySlider = () =>
    import(
        /* webpackChunkName:
"tiny-slider" */
        'components/ui/slider/tiny'
    );
Vue.component('r-tiny-slider', TinySlider);

// import Modal from 'components/ui/modal/modal';
Vue.component('r-modal', Modal);

import LoadingBounce from 'components/ui/loading/bouncing';
Vue.component('r-loading-bounce', LoadingBounce);

import LoadingSpinner from 'components/ui/loading/spinner';
Vue.component('r-loading-spinner', LoadingSpinner);

const Upload = () =>
    import(
        /* webpackChunkName:
"upload" */
        'components/ui/upload/upload'
    );
Vue.component('r-upload', Upload);

const Dropzone = () =>
    import(
        /* webpackChunkName:
"dropzone" */
        'components/ui/upload/dropzone'
    );
Vue.component('r-dropzone', Dropzone);

const Draggable = () =>
    import(
        /* webpackChunkName:
"draggable" */
        'components/ui/draggable/draggable'
    );
Vue.component('r-draggable', Draggable);

Vue.component('r-tab', Tab);
Vue.component('r-tabs', Tabs);

Vue.component('r-tags', () => import(/* webpackChunkName: "ui-tags" */ './ui/tags.js'));
