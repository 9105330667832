<template>
    <div class="editable">
        <component
            v-if="isEditing"
            :is="typeComponent"
            :id="id"
            :name="name"
            :placeholder="placeholder"
            :value="value"
            rows="3"
            v-focus
            @input="onInputChange"
            @change="onInputChange"
            @blur="onBlur"
        />
        <a v-else href="#" :class="[isEmptyClass]" @click="onEditClick">{{ display }}</a>
    </div>
</template>

<script>
import { isEmpty } from 'utils/lodash';
export default {
    props: {
        id: {
            type: String,
            default: 'editable-input-id',
        },
        name: {
            type: String,
            default: 'editable-input-id',
        },
        placeholder: {
            type: String,
            default: null,
        },
        default: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Array],
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'input',
            validator: function (value) {
                return ['input', 'textarea'].indexOf(value) !== -1;
            },
        },
    },
    data: () => ({
        isEditing: false,
    }),
    computed: {
        typeComponent() {
            return `r-${this.type}`;
        },
        display() {
            return !isEmpty(this.value) ? this.value : this.default;
        },
        isEmpty() {
            return isEmpty(this.value);
        },
        isEmptyClass() {
            return this.isEmpty ? 'empty' : '';
        },
    },
    methods: {
        onEditClick() {
            if (this.readonly) {
                return;
            }

            this.isEditing = true;
        },
        onInputChange(value) {
            this.$emit('change', value);
        },
        onBlur() {
            this.$emit('close');
            this.isEditing = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.editable {
    a {
        text-decoration: none;
        color: #000;
        font-size: 14px;
        font-family: Avenir-light;
        text-align: left !important;

        &:hover {
            border-bottom: dashed 1px #ccc;
        }

        &:hover,
        &:visited,
        &:active {
            color: #000;
        }

        &.empty {
            font-style: normal;
        }
    }

    input,
    textarea {
        margin-bottom: 0px;
    }

    textarea {
        min-height: 10px;
    }
}
</style>
