import http from 'services/axios';

class User {
    static all(page) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/maps/users', {
            params: {
                page: page,
            },
        });
    }

    static search(keyword) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/maps/users', {
            params: {
                query: keyword,
            },
        });
    }

    static get(user) {
        return http.get(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + user);
    }

    static store(user) {
        return http.post(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users', user);
    }

    static assignArea(areaId, userId) {
        return http.post(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/areas/' + areaId + '/users/' + userId);
    }

    static invite(userId) {
        return http.post(process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1/users/' + userId + '/invite');
    }
}

export default User;
