<script>
import { includes, debounce } from 'utils/lodash';
export default {
    data: () => ({
        viewport: null,
        windowWidth: null,
    }),
    computed: {
        isViewPortXs() {
            return this.viewport == 'xs';
        },
        isViewPortSm() {
            return this.viewport == 'sm';
        },
        isViewPortMd() {
            return this.viewport == 'md';
        },
        isViewPortLg() {
            return this.viewport == 'lg';
        },
        isLargerThanXs() {
            return includes(['sm', 'md', 'lg', 'xl'], this.viewport);
        },
        isLargerThanSm() {
            return includes(['md', 'lg', 'xl'], this.viewport);
        },
        isLargerThanMd() {
            return includes(['lg', 'xl'], this.viewport);
        },
        isLargerThanLg() {
            return includes(['xl'], this.viewport);
        },
        isMobile() {
            return this.windowWidth <= 576;
        },
    },
    watch: {
        $mq: {
            immediate: true,
            handler: function () {
                this.viewport = this.$mq;
            },
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        handleResize: debounce(function () {
            this.windowWidth = window.outerWidth;
        }, 200),
        isLargerThan(v) {
            switch (v) {
                case 'xs':
                    return this.isLargerThanXs;
                case 'sm':
                    return this.isLargerThanSm;
                case 'md':
                    return this.isLargerThanMd;
                case 'lg':
                    return this.isLargerThanLg;
            }
        },
    },
};
</script>
