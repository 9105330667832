<template>
    <r-modal class="availability-modal overflow-y-hidden" :open="open" dismissable v-on="$listeners">
        <template #header>
            <h3 class="text-lg leading-6 font-medium text-gray-900 text-center">Availability</h3>

            <slot name="header" />

            <div class="calendar-header">
                <span>S</span>
                <span>M</span>
                <span>T</span>
                <span>W</span>
                <span>T</span>
                <span>F</span>
                <span>S</span>
            </div>
        </template>

        <div class="availability-calendars">
            <availability-calendar
                v-for="date in calendarDates"
                :key="date"
                :date="date"
                :availabilities="unit.properties['temporary-housing-details-availability']"
            />
        </div>

        <template #footer>
            <slot name="footer" />
        </template>
    </r-modal>
</template>

<script>
import AvailabilityCalendar from './availability-calendar';
import dayjs from 'dayjs';

export default {
    components: {
        AvailabilityCalendar,
    },
    data: () => ({
        dayjs: dayjs,
    }),
    props: {
        unit: {
            type: Object,
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        calendarDates() {
            let onMonth = dayjs().startOf('month');
            const lastMonth = onMonth.add(1, 'year');
            const dates = [];

            while (onMonth.isBefore(lastMonth)) {
                dates.push(onMonth.format('YYYY-MM-DD'));
                onMonth = onMonth.add(1, 'month');
            }

            return dates;
        },
    },
};
</script>

<style lang="scss">
.availability-modal {
    .timezone {
        text-align: center;
        color: #9b9b9b;
        font-size: 15px;
        margin-bottom: 0;
    }

    .calendar-header {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin-top: 50px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eaeaea;

        span {
            text-align: center;
            color: #747474;
        }
    }

    .availability-calendars {
        height: 50vh;
        overflow-y: scroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .availability-calendar {
            margin-bottom: 50px;
        }
    }
}
</style>
