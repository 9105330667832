import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import modules from './modules';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    state: {
        errorMessages: null,
    },
    mutations: {
        setErrorMessages(state, errorMessages) {
            state.errorMessages = errorMessages;
        },
    },
    modules,
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});

export default store;
