import {isEmpty} from 'utils/lodash';
import Annotation from "models/Annotation";

const state = {
  annotation: null,
  annotationCopy: JSON.parse(localStorage.getItem('ANNOTATION_COPY'))
};

// getters
const getters = {
  annotation: state => state.annotation,
  editing: state => !isEmpty(state.annotation),
  annotationCopy: state => state.annotationCopy,
  hasCopy: (state) => !isEmpty(state.annotationCopy),
};

// actions
const actions = {
  edit({
    commit
  }, annotation) {
    commit('SET_EDITING', annotation);
  },
  cancel({commit}) {
    commit('CLEAR_EDITING');
  },
  async copyAnnotation({
    commit
  }, annotation) {
    delete annotation.id;
    await commit('SET_COPY_ANNOTATION_CONTENTS', {
        ...annotation,
        name: `${annotation.name} - Cloned`
    });
  },
  async clearAnnotation({commit}) {
    await commit('CLEAR_COPY_ANNOTATION_CONTENTS');
  },
  async updateAnnotation({}, { workOrderId, mapId, layerId, annotationId, payload }) {
    const { data } = await Annotation.update({
      workOrderId,
      mapId,
      layerId,
      annotationId,
      payload
    })

    return data.data
  },
  async storeAnnotation({}, { workOrderId, mapId, layerId, payload }) {
    const { data } = await Annotation.store({
      workOrderId,
      mapId,
      layerId,
      payload
    })

    return data
  },
  async deleteAnnotation({}, { workOrderId, mapId, layerId, annotationId }) {
    const { data } = await Annotation.destroy({ workOrderId, mapId, layerId, annotationId })
    return data
  }
};

// mutations
const mutations = {
  SET_EDITING(state, annotation) {
    state.annotation = annotation;
  },
  CLEAR_EDITING(state) {
    state.annotation = null;
  },
  SET_COPY_ANNOTATION_CONTENTS(state, annotation) {
    Object.assign(annotation, {order: 1});
    state.annotationCopy = annotation;
    localStorage.setItem('ANNOTATION_COPY', JSON.stringify(annotation));
  },
  CLEAR_COPY_ANNOTATION_CONTENTS(state) {
    state.annotationCopy = {}
    localStorage.removeItem('ANNOTATION_COPY')
  }
};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
