<template>
    <span />
</template>

<script>
import { mapGetters } from 'vuex';
import MapService from 'services/mapservice';
export default {
    props: {
        id: {
            type: String,
            default: '__DESTINATION__',
        },
    },
    data: () => ({
        mapBox: null,
        sourceId: '__DESTINATION_SOURCE__',
    }),
    computed: {
        ...mapGetters('map', ['center', 'destinationTitle']),
    },
    created() {
        this.$events.$on('map:loaded', this.onMapLoaded);
        this.$events.$on('map:destination:click', this.onMarkerClicked);
    },
    beforeDestroy() {
        this.reset();

        this.$events.$off('map:loaded', this.onMapLoaded);
        this.$events.$off('map:destination:click', this.onMarkerClicked);
    },
    methods: {
        onMarkerClicked() {
            this.$events.$emit('map:popup:enter', {
                coordinates: this.center,
                html: `<b>${this.destinationTitle}</b>`,
                options: {
                    offset: 19,
                },
            });
        },
        onMapLoaded(mapBox) {
            this.mapBox = mapBox;
            this.buildLayers();
        },
        reset() {
            try {
                if (this.mapBox.map.getLayer(this.id)) {
                    this.mapBox.map.removeLayer(this.id);
                }

                if (this.mapBox.map.getSource(this.sourceId)) {
                    this.mapBox.map.removeSource(this.sourceId);
                }
            } catch (e) {}
        },
        buildLayers() {
            // Remove ids
            try {
                this.reset();

                this.mapBox.map.addSource(this.sourceId, this.getSource());

                let setup = this.getSetup();

                this.addMapLayer(setup);
            } catch (e) {}
        },
        addMapLayer(setup) {
            MapService.addLayer(setup);
        },
        getSetup() {
            return {
                id: this.id,
                type: 'symbol',
                layout: {
                    'icon-allow-overlap': true,
                    'icon-image': 'relocity-destination-marker',
                },
                source: this.sourceId,
            };
        },
        getSource() {
            return {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            geometry: {
                                type: 'Point',
                                coordinates: this.center,
                            },
                        },
                    ],
                },
            };
        },
    },
};
</script>
