<template>
    <div class="max-w-md w-auto p-4 bg-white z-10 rounded" :class="{ 'pt-8': allowFavoriting }">
        <a
            v-if="allowFavoriting"
            id="favoriteButton"
            class="absolute top-3 right-4 cursor-pointer"
            @mouseover="onFavoriteMouseOver"
            @mouseleave="onFavoriteMouseLeave"
            @click="onFavoriteClick"
        >
            <r-icon v-if="!hoverFavorite && !recommended" name="heart" color="#aaa" icon-style="fal" size="lg" />
            <r-icon v-else name="heart" color="#f54" icon-style="fas" size="lg" />
        </a>
        <div v-if="hasMiniDetailView">
            <a v-if="miniDetailUrl" :href="miniDetailUrl" target="_blank">
                <div v-html="miniDetailView" />
            </a>
            <div v-else v-html="miniDetailView" />
        </div>
        <div v-else class="flex gap-6">
            <div class="flex-none relative">
                <icon-image :icon="icon" />
                <span v-if="layer.type === 'area_tour'" class="absolute area-tour-number font-bold text-white text-xl">
                    {{ properties.order }}
                </span>
            </div>
            <div class="flex-1">
                <div class="font-bold text-md">
                    {{ properties.name }}
                </div>
                <div v-if="properties.description !== 'null'" class="text-sm mini-detail-description">
                    {{ properties.description }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IconImage from '@/components/layers/types/custom/components/icon/image';
import marked from 'marked';
import { mapActions } from 'vuex';
import { debounce } from '@/utils/lodash';

export default {
    components: {
        IconImage,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        workOrderId: {
            type: [Number, String],
        },
        mapId: {
            type: [String, Number],
            required: true,
        },
        allowFavoriting: {
            type: Boolean,
            default: true,
        },
        layerId: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            hoverFavorite: false,
            recommended: this.record.properties.is_recommended,
        };
    },
    computed: {
        displayDetails() {
            if (!this.properties.display_details) {
                return null;
            }

            try {
                return JSON.parse(this.properties.display_details);
            } catch (e) {}
            return this.properties.display_details;
        },
        hasMiniDetailView() {
            if (!this.displayDetails) {
                return false;
            }
            return this.displayDetails.description;
        },
        miniDetailView() {
            const type = this.displayDetails.description_format;
            const description = this.displayDetails.description;

            return type === 'markdown' ? marked(description) : description;
        },
        layer() {
            try {
                return JSON.parse(this.properties.layer);
            } catch (e) {}
            return this.properties.layer;
        },
        miniDetailUrl() {
            if (!this.displayDetails) {
                return null;
            }
            return this.displayDetails.url;
        },
        properties() {
            return this.record.properties;
        },
        icon() {
            return this.layer.icon;
        },
        isDataContentRecord() {
            if (this.layerId) {
                return true;
            }
            return this.layer.type === 'data_content';
        },
    },
    watch: {
        ['record.properties']: {
            deep: true,
            immediate: true,
            handler: 'onRecordChange',
        },
    },
    methods: {
        ...mapActions('annotation', ['updateAnnotation', 'storeAnnotation', 'deleteAnnotation']),
        ...mapActions('records', ['syncDataContentFavorites']),
        async onFavoriteClick() {
            if (this.isDataContentRecord) {
                this.handleFavoriteDataContentAnnotation();
            } else {
                this.handleFavoriteCustomAnnotation();
            }
        },
        handleFavoriteCustomAnnotation() {
            return this.updateFavorite();
        },
        handleFavoriteDataContentAnnotation() {
            // Update record, do not delete
            if (this.record.properties.platform_id && this.record.properties.is_liked) {
                return this.updateFavorite(true);
            }

            // Delete record
            if (this.record.properties.platform_id && !this.record.properties.is_liked) {
                return this.deleteFavorite(true);
            }

            return this.storeFavorite(true);
        },
        updateFavorite: debounce(async function (isDataContentRecord = false) {
            await this.updateAnnotation({
                workOrderId: this.workOrderId,
                mapId: this.mapId,
                layerId: this.layerId ?? this.layer.id,
                annotationId: isDataContentRecord ? this.properties.platform_id : this.properties.id,
                payload: {
                    is_recommended: !this.recommended,
                },
            });

            this.toggleRecommended();
            if (isDataContentRecord) {
                await this.updateDataContentFavorite(
                    this.record.properties.is_liked,
                    this.recommended,
                    this.record.properties.record_id,
                    this.record.properties.platform_id
                );
            }
            this.$emit('annotation-updated');
        }, 200),
        storeFavorite: debounce(async function (isDataContentRecord = false) {
            // Create record
            let annotation = await this.storeAnnotation({
                workOrderId: this.workOrderId,
                mapId: this.mapId,
                layerId: this.layerId ?? this.layer.id,
                payload: {
                    name: this.displayDetails.name,
                    description: this.displayDetails.description,
                    description_format: this.displayDetails.description_format ?? 'text',
                    address: this.displayDetails.name,
                    coordinate: JSON.stringify(this.record.geometry),
                    data_content_record_id: this.properties.record_id,
                    record_unique_id: this.properties.unique_id,
                    is_recommended: true,
                    url: this.displayDetails.url,
                },
            });

            this.recommended = !this.recommended;
            if (isDataContentRecord) {
                await this.updateDataContentFavorite(
                    annotation.is_liked,
                    this.recommended,
                    annotation.data_content_record_id,
                    annotation.record_unique_id,
                    annotation.id
                );
            }

            this.$emit('annotation-updated');
            return annotation;
        }, 200),
        deleteFavorite: debounce(async function (isDataContentRecord = false) {
            await this.deleteAnnotation({
                workOrderId: this.workOrderId,
                mapId: this.mapId,
                layerId: this.layerId ?? this.layer.id,
                annotationId: isDataContentRecord ? this.properties.platform_id : this.properties.id,
            });

            this.toggleRecommended();
            if (isDataContentRecord) {
                await this.updateDataContentFavorite(
                    this.record.properties.is_liked,
                    this.recommended,
                    this.record.properties.record_id,
                    this.record.properties.record_unique_id,
                    undefined
                );
            }
            this.$emit('annotation-updated');
        }, 200),
        updateDataContentFavorite: debounce(async function (
            isLiked,
            isRecommended,
            dataContentId,
            recordUniqueId,
            platformId
        ) {
            await this.syncDataContentFavorites(
                {
                    layerId: this.layerId ?? this.layer.id,
                    favoriteAnnotations: [
                        {
                            data_content_record_id: dataContentId,
                            record_unique_id: recordUniqueId,
                            is_liked: isLiked ?? false,
                            is_recommended: isRecommended,
                            id: platformId,
                        },
                    ],
                },
                false
            );
        },
        200),
        onFavoriteMouseOver() {
            this.hoverFavorite = true;
        },
        onFavoriteMouseLeave() {
            this.hoverFavorite = false;
        },
        toggleRecommended() {
            this.recommended = !this.recommended;
        },
        onRecordChange(newData) {
            this.recommended = newData.properties ? newData.properties.is_recommended : newData.is_recommended;
        },
    },
};
</script>

<style lang="scss" scoped>
.mini-detail-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.area-tour-number {
    top: 6px;
    left: 18px;
}
</style>
