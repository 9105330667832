<template>
    <div :class="classes">
        <h3>{{ firstCalendarDay.format('MMMM YYYY') }}</h3>
        <div class="calendar">
            <div v-for="(date, i) in calDays" :key="i" :class="dateClasses(date)">
                <span>{{ date == 0 ? '' : date }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { range } from '@/utils/lodash';

export default {
    data: () => ({
        dayjs: dayjs,
    }),
    props: {
        date: {
            type: String,
            required: true,
        },
        availabilities: {
            type: Array,
            required: true,
        },
    },
    computed: {
        classes() {
            return ['availability-calendar'].concat(this.isFullyUnavailable ? 'fully-booked' : 'available');
        },
        firstCalendarDay() {
            return dayjs(this.date).startOf('month');
        },
        lastCalendarDay() {
            return this.firstCalendarDay.endOf('month');
        },
        // return an array that represents the days of a calendar arranged in a weekly grid
        calDays() {
            // create an range of days
            const mat = range(1, this.lastCalendarDay.date() + 1);

            // pad it with 0's to push the first day to the correct starting day of the week
            // and to add 0's after the last day of the month
            const startPad = new Array(this.firstCalendarDay.day()).fill(0);
            const endPad = new Array(6 - this.lastCalendarDay.day()).fill(0);

            return startPad.concat(mat, endPad);
        },
        // return an array of all days that are available in this month
        availableDays() {
            const available = [];
            this.availabilities.forEach((availability) => {
                // skip dates that are not specifically marked as available
                if (availability.status.toLowerCase() !== 'available') {
                    return;
                }

                let onAvailableDay = this.parseDate(availability.start);
                const lastAvailableDay = this.parseDate(availability.end);

                while (onAvailableDay.isSameOrBefore(lastAvailableDay)) {
                    if (this.dayIsInOurCalendar(onAvailableDay)) {
                        available[onAvailableDay.date()] = true;
                    }
                    onAvailableDay = onAvailableDay.add(1, 'day');
                }
            });

            return available;
        },
        isFullyUnavailable() {
            return this.availableDays.length === 0 || this.availableDays.length === this.lastCalendarDay.date();
        },
    },
    methods: {
        // return true if the dayjs object falls within our calendar bounds
        dayIsInOurCalendar(djs) {
            return djs.isSameOrAfter(this.firstCalendarDay) && djs.isSameOrBefore(this.lastCalendarDay);
        },
        dateClasses(date) {
            const classes = ['date'];

            if (!this.availableDays[date]) {
                classes.push('blocked');
            }

            if (date === 0) {
                classes.push('empty');
            }

            return classes;
        },
        parseDate(str) {
            const ymd = str.match(/\d{4}-\d{2}-\d{2}/)[0];
            return dayjs(ymd);
        },
    },
};
</script>

<style lang="scss">
.availability-calendar {
    font-family: 'Avenir';

    h3 {
        margin-left: 10px;
        font-weight: 500;
        font-size: 21px;
    }

    .calendar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;

        .date {
            padding: 10px 0;

            &.blocked span {
                text-decoration: line-through;
                color: #b0b0b0;
            }
        }
    }
}
</style>
