<template>
    <div class="record-view">
        <span v-if="record === null">
            <r-loading-spinner :visible="true"></r-loading-spinner>
        </span>
        <span v-else>
            <span v-if="hasNoTemplate"> No Template is assigned to this category. </span>
            <span v-else>
                <component :is="templateComponent" :record="record" viewing-from="standalone" />
            </span>
        </span>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        category: {
            type: String,
            required: true,
        },
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data: () => ({
        record: null,
    }),
    computed: {
        // We've loaded the record and it has no defined template
        hasNoTemplate() {
            return this.record != null && this.templateName === null;
        },
        templateName() {
            return this.record?.properties?.display_details?.template;
        },
        templateComponent() {
            return require(`@/components/records/templates/${this.templateName}/details.vue`).default;
        },
    },
    async created() {
        this.record = await this.getRecordGeoJSON({
            category: this.category,
            id: this.id,
        });
    },
    methods: {
        ...mapActions('records', { getRecordGeoJSON: 'getGeoJSON' }),
    },
};
</script>

<style lang="scss" scoped>
.pill-btn {
    display: block;
}
</style>
