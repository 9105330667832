<script>
import { get } from 'utils/lodash';
export default {
    methods: {
        rowProperty(propName, defaultValue, row) {
            row = row ?? this.row;
            const value = get(row, propName, defaultValue);

            try {
                return JSON.parse(value);
            } catch {
                return value;
            }
        },
    },
};
</script>
