<template>
    <div class="bouncing-indicator">
        <div class="modal-wrapper-outer flex items-center h-100 justify-center">
            <span class="location_indicator" />
            <slot name="loading">
                <span class="copy">Loading...</span>
            </slot>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import 'scss/loading';
</style>
