import Vue from 'vue';
import App from './App';
import VueAnalytics from 'vue-analytics';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import './services/events';
import './registerServiceWorker';
import './utils/filters';
import './utils/toasts';
import './utils/ui';
import './utils/mq';
import './utils/portal';
import './directives';
import router from './router';
import store from './store';
import auth from '@relocity/auth';
import authAddons from '@/auth-addons';

import '@/components/ui.js';
import '@relocity/ui/dist/ui.css';

Vue.config.productionTip = false;

Vue.use(auth, {
    appUrl: process.env.VUE_APP_APP_URL,
    ssoUrl: process.env.VUE_APP_RELOCITY_PLATFORM_URL,
    callbackUrl: process.env.VUE_APP_APP_URL + '/callback',
    clientId: process.env.VUE_APP_RELOCITY_PLATFORM_OAUTH_CLIENT_ID,
    scopes: '*',
    skipOauthLogout: true,
});

Vue.use(VueMeta)
    .use(authAddons)
    .use(VueAnalytics, { id: process.env.VUE_APP_ANALYTICS_TRACKING })
    .use(VueGtag, { config: { id: process.env.VUE_APP_GOOGLE_GTAG } });

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
