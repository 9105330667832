import platform from 'services/api/platform';

const state = {};

// getters
const getters = {};

const actions = {
  async create({}, {gallery, file}) {
    let type = file.mediaType;

    let response = await platform.post(`galleries/${gallery}/${type}`, {
      uuid: file.signed.uuid,
      key: file.signed.key,
      bucket: file.signed.bucket,
      extension: file.extension
    });

    let data = response.data.data;

    return data;
  },
  async update({}, {gallery, type, media, payload}) {
    let response = await platform.put(`galleries/${gallery}/${type}/${media}`, payload);

    let data = response.data.data;

    return data;
  },
  async delete({}, {gallery, type, media}) {
    let response = await platform.delete(`galleries/${gallery}/${type}/${media}`);

    let data = response.data.data;

    return data;
  }
};

// mutations
const mutations = {};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
