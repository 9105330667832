<template>
    <div class="map-provider-google">
        <span>Map data &copy;{{ getCurrentYear }} Google</span>
    </div>
</template>

<script>
import { year } from "utils/dates";
export default {
  computed: {
    getCurrentYear: function() {
      return year();
    }
  }
};
</script>
