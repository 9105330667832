import http from 'services/axios';

const BASE_URL = process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/api/v1';

class Layer {
    static async create(user, map, layer) {
        const response = await http.post(this.getUrl(user, map), layer);
        return response.data.data;
    }

    static async createWorkOrderMapLayer(workOrder, layer) {
        const response = await http.post(this.getWorkOrderUrl(workOrder), layer);
        return response.data.data;
    }

    static async update(user, map, layer) {
        const response = await http.patch(`${this.getUrl(user, map)}/${layer.id}`, layer);
        return response.data.data;
    }

    static async updateWorkOrderMapLayer(workOrder, layer) {
        const response = await http.patch(`${this.getWorkOrderUrl(workOrder)}/${layer.id}`, layer);
        return response.data.data;
    }

    static async delete(user, map, layer) {
        return await http.delete(`${this.getUrl(user, map)}/${layer.id}`);
    }

    static async deleteWorkOrderMapLayer(workOrder, layer) {
        return await http.delete(`${this.getWorkOrderUrl(workOrder)}/${layer.id}`);
    }

    static async reorder(user, map, layerIds) {
        return await http.put(`${this.getUrl(user, map)}/reorder`, { layer_ids: layerIds });
    }

    static async reorderWorkOrderMapLayer(workOrder, layerIds) {
        return await http.put(`${this.getWorkOrderUrl(workOrder)}/reorder`, { layer_ids: layerIds });
    }

    static getUrl(user, map) {
        return `${BASE_URL}/users/${user}/maps/${map}/layers`;
    }

    static getWorkOrderUrl(workOrder) {
        return `${BASE_URL}/work-orders/${workOrder}/map/layers`;
    }
}

export default Layer;
