<template>
    <div class="map-provider-apple">
        <img
            class="map-provider-icon"
            src="https://cdn.apple-mapkit.com/mk/5.16.0/images/logos/logo.png"
            alt="Apple Inc. icon"
        />
        <div class="legal-wrapper">
            <button
                class="legal-btn"
                @click.stop="showLegalLinks = !showLegalLinks"
            >
                Legal
            </button>
            <div class="legal-links" v-if="showLegalLinks">
                <div
                    @click.stop="showLegalLinks = !showLegalLinks"
                    class="legal-link close-legal-info-btn"
                    role="button"
                    aria-label="Close"
                    tabindex="-1"
                >
                    <svg viewBox="0 0 11 11" class="mk-icon" focusable="false">
                        <g>
                            <path
                                d="M6.20710678,5.5 L10.3535534,9.6464466 L9.6464466,10.3535534 L5.5,6.20710678 L1.35355339,10.3535534 L0.646446609,9.64644661 L4.79289322,5.5 L0.646446602,1.35355338 L1.35355338,0.646446602 L5.5,4.79289322 L9.64644661,0.646446609 L10.3535534,1.35355339 L6.20710678,5.5 Z"
                            ></path>
                        </g>
                    </svg>
                </div>
                <div
                    @click.stop="showLegalLinks = !showLegalLinks"
                    class="legal-link"
                    tabindex="-1"
                >
                    <a
                        href="https://www.apple.com/legal/internet-services/maps/terms-en.html"
                        target="_blank"
                        >Terms of Use</a
                    >
                </div>
                <div
                    @click.stop="showLegalLinks = !showLegalLinks"
                    class="legal-link"
                    tabindex="-1"
                >
                    <a href="https://www.apple.com/privacy/" target="_blank">Privacy</a>
                </div>
                <div
                    @click.stop="showLegalLinks = !showLegalLinks"
                    class="legal-link tomtom-link"
                    tabindex="-1"
                >
                    <a
                        href="https://gspe21-ssl.ls.apple.com/html/attribution-136.html"
                        target="_blank"
                        >Data from
                        <img
                            class="tomtom-logo"
                            src="https://cdn.apple-mapkit.com/mk/5.16.0/images/legal/tomtom_2x.png"
                            alt="TomTom Logo"/>, others
                        <span class="right-caret"
                            ><svg viewBox="0 0 7 9" focusable="false">
                                <g><path d="M1,1 L5,4.5 L1,7.5"></path></g></svg></span
                    ></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                showLegalLinks: false,
            }
        }
    }
</script>
