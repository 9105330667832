<template>
    <div class="relocity-housing template">
        <div class="main-content">
            <header class="relative">
                <div class="rent-or-sale badge">
                    {{ isRental ? 'For Rent' : 'For Sale' }}
                </div>

                <div v-if="gallery.length > 0" class="gallery-page-num badge">
                    {{ galleryIdx + 1 }}/{{ numGalleryImages }}
                </div>

                <div class="images">
                    <div class="keen-slider" ref="slider">
                        <div class="keen-slider__slide lazy__slide" v-for="(src, idx) in gallery" :key="idx">
                            <img :src="loaded[idx] ? src : ''" />
                        </div>
                    </div>
                </div>
            </header>

            <div class="details">
                <div class="pricing-and-logo">
                    <h3 class="pricing" v-html="pricingInfoHTML" />
                    <img :class="providerClasses" :src="providerLogo" />
                </div>

                <div class="address">
                    <p>{{ props.address }}</p>
                    <p>{{ props.city }}, {{ props.state }} {{ props.zip }}</p>
                </div>

                <ul class="beds-baths-sqft">
                    <li class="beds">
                        <r-icon name="bed-alt" icon-style="fad" />
                        {{ props.bedrooms }} beds
                    </li>
                    <li class="baths">
                        <r-icon name="bath" icon-style="fad" />
                        {{ props.bathrooms }} baths
                    </li>
                    <li class="sqft">
                        <r-icon name="home-heart" icon-style="fad" />
                        {{ props.square_footage }} sqft
                    </li>
                </ul>
            </div>

            <div v-if="props.description" class="description">
                <p>{{ props.description }}</p>
            </div>
        </div>
        <div class="sticky footer">
            <a :href="this.props.details_url" target="blank"> More Property Details </a>
        </div>
    </div>
</template>

<script>
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import { layerDetails } from 'mixins';

export default {
    mixins: [layerDetails],
    props: {
        record: {
            type: Object,
            required: true,
        },
        showMap: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        galleryIdx: 0,
        currencyFormatter: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }),
        loaded: [],
        slider: null,
    }),
    mounted() {
        this.buildSlider();
    },
    beforeDestroy() {
        if (this.slider) {
            this.slider.destroy();
        }
    },
    computed: {
        row() {
            return this.record;
        },
        props() {
            return this.record.properties;
        },
        gallery() {
            return this.rowProperty('properties.images');
        },
        numGalleryImages() {
            return this.gallery.length;
        },
        isRental() {
            return this.props.status === 'For Rent';
        },
        pricingInfoHTML() {
            return this.isRental ? `Rent ${this.price}<span>/month</span>` : this.price;
        },
        price() {
            // if the price is pre-formatted, leave it alone. Otherwise, format it
            return isNaN(parseFloat(this.props.price))
                ? this.props.price
                : this.currencyFormatter.format(parseFloat(this.props.price));
        },
        providerLogo() {
            const logos = {
                zillow: '/images/templates/relocity-housing-template/zillow.png',
                trulia: '/images/templates/relocity-housing-template/trulia.svg',
                zumper: '/images/templates/relocity-housing-template/zumper.png',
            };

            return logos[this.props.provider.toLowerCase()];
        },
        providerClasses() {
            return `provider-logo ${this.props.provider.toLowerCase()}`;
        },
    },
    methods: {
        buildSlider() {
            if (this.slider) {
                this.slider.destroy();
            }

            this.slider = new KeenSlider(this.$refs.slider, {
                afterChange: (s) => {
                    const idx = s.details().relativeSlide;

                    if (isNaN(idx)) {
                        return;
                    }

                    this.galleryIdx = idx;

                    // load in two images
                    this.loaded[this.galleryIdx] = true;
                    this.loaded[this.galleryIdx + 1] = true;

                    this.$forceUpdate();
                },
                loop: true,
                initial: 0,
            });
        },
    },
};
</script>

<style>
.lazy__slide {
    height: 50vw;
    background: rgb(255, 75, 64);
    background-color: black;
    background-image: url('https://keen-slider.io/images/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px;
    min-height: auto;
}

@media (min-width: 768px) {
    .lazy__slide {
        height: 300px;
    }
}

.lazy__slide img {
    background-color: transparent;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
}
</style>

<style lang="scss">
.relocity-housing.template {
    /* Header */
    header {
        min-height: 40px;

        .badge {
            position: absolute;
        }

        .rent-or-sale.badge {
            background-color: #23b899;
            color: white;
            padding: 3px 8px;
            bottom: 10px;
            left: 10px;
            z-index: 10;
            font-size: 12px;
            border-radius: 8px;
        }

        .gallery-page-num.badge {
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            padding: 3px 8px;
            bottom: 10px;
            right: 10px;
            z-index: 10;
            font-size: 12px;
            text-shadow: 1px 1px 1px black;
            border-radius: 8px;
        }
    }

    /* Details */
    .details {
        padding: 20px;
        cursor: default !important;

        .pricing-and-logo {
            display: flex;
            justify-content: space-between;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #191919;

                span {
                    font-size: 14px;
                }
            }

            .provider-logo {
                height: 40px;

                &.zumper {
                    height: 20px;
                }
            }
        }

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }

        .beds-baths-sqft {
            display: flex;
            justify-content: space-around;
            margin-top: 30px;
        }
    }

    .description {
        padding: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
    }

    #map {
        height: 200px;
    }

    #marker {
        background-color: #22c17a;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px 15px;
        border: 1px solid white;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);
            width: 0;
            border-top: 5px solid #22c17a;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }

    .sticky.footer {
        position: sticky;
        bottom: 0;
        background: white;
        box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25);
        padding: 28px;
        z-index: 10;

        a {
            margin: auto;
            text-align: center;
            background: black;
            color: white;
            display: block;
            padding: 10px;
            width: 100%;
            border-radius: 21px;
        }
    }
}
</style>
