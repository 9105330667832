import { map } from 'utils/lodash';
import { MAP_PROVIDERS } from 'config';

const state = {
  mapProviders: MAP_PROVIDERS
};

// getters
const getters = {
  mapProviders: state => map(state.mapProviders, provider => {
    return {key: provider, title: provider};
  })
};

const actions = {};

// mutations
const mutations = {};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
