import Map from 'models/Map';
import Layer from 'models/Layer';
import {replace} from 'utils/helpers';
import events from 'services/events';
import {
    get,
    merge,
    find,
    filter,
    cloneDeep,
    sortBy,
    isEmpty
} from 'utils/lodash';
import MapService from "@/services/mapservice";

const state = {
    loaded: false,
    styleReady: false,
    user: null,
    config: {},
    public: false,
    changingProvider: false,
    mapCenter: null,
    mapBounds: null
};

// getters
const getters = {
    isPublic: (state) => state.public,
    isChangingProvider: (state) => state.changingProvider,
    user: (state) => state.user,
    loaded: (state) => state.loaded,
    isStyleReady: (state) => state.styleReady,
    config: (state) => state.config,
    id: (state) => get(state.config, 'id'),
    title: (state) => get(state.config, 'title'),
    destinationTitle: (state) => get(state.config, 'destination_title'),
    style: (state) => state.config.style,
    layers: (state) => sortBy(state.config.layers, ['order']),
    provider: (state) => state.config.provider,
    destination: (state) => state.config.destination,
    permanentHomeCoordinates: (state, getters) => get(getters.workOrder, 'permanentHouseAddress.location.coordinates'),
    workOrder: (state) => state.config.work_order,
    workOrderId: (state, getters) => get(getters.workOrder, 'id'),
    latitude: (state) => get(state.config, 'center.latitude'),
    longitude: (state) => get(state.config, 'center.longitude'),
    center: (state, getters) => {
        return [getters.longitude, getters.latitude];
    },
    boundsRectangle: state => `${state.mapBounds._sw.lat},${state.mapBounds._sw.lng}|${state.mapBounds._ne.lat},${state.mapBounds._ne.lng}`
};

const actions = {
    async loadMap({
        commit
    }, {user, workOrder, map}) {
        let response = null;

        if (workOrder) {
            response = await Map.getWorkOrderMap(workOrder);
        } else if (user && map) {
            response = await Map.get(user, map);
        } else {
            response = await Map.published(map);
        }

        let data = response.data.data;

        await commit('SET_MAP', data);

        return data;
    },
    async setMapConfig({
        commit
    }, config) {
        await commit('SET_MAP', config);
    },
    async overwriteMap({
        commit,
        getters
    }, payload) {
        let response = null;

        if (getters.workOrderId) {
            response = await Map.updateWorkOrderMap(getters.workOrderId, payload);
        } else if (getters.user && getters.id) {
            response = await Map.update(getters.user, getters.id, payload);
        } else {
            throw new Error('Invalid Update. Missing User.');
        }

        let data = response.data.data;

        await commit('UPDATE_MAP', data);

        events.$emit('map:updated', data);

        return data;
    },
    async updateMap({
        commit,
        dispatch,
        getters
    }, payload) {
        let content = cloneDeep(getters.config);

        let data = await dispatch('overwriteMap', merge(content, payload));

        await commit('UPDATE_MAP', data);

        return data;
    },
    async saveMap({dispatch, getters}) {
        return await dispatch('overwriteMap', cloneDeep(getters.config));
    },
    async saveMapWithoutOverwriting({ getters }) {
      let payload = cloneDeep(getters.config);

        if (getters.workOrderId) {
            return await Map.updateWorkOrderMap(getters.workOrderId, payload);
        } else if (getters.user && getters.id) {
            return await Map.update(getters.user, getters.id, payload);
        } else {
            throw new Error('Invalid Update. Missing User.');
        }
    },
    async setUser({
        commit
    }, payload) {
        await commit('SET_USER', payload);
    },
    async saveLayer({
        getters,
        commit
    }, layer) {

        let config = cloneDeep(getters.config);
        let updated = null;

        if (getters.workOrderId) {
            updated = await Layer.updateWorkOrderMapLayer(getters.workOrderId, layer)
        } else if (getters.user && getters.id) {
            updated = await Layer.update(getters.user, getters.id, layer);
        }

        if (!updated) {
            throw new Error('Invalid Layer Save.');
        }

        config.layers = config.layers.map(
            (l) => l.id === layer.id ? updated : l
        )

        // update map with new configuration
        await commit('UPDATE_MAP', config);
        events.$emit('map:updated', config);
    },
    async removeLayer({
        getters,
        commit
    }, layer) {
        let config = cloneDeep(getters.config);
        let layers = cloneDeep(getters.layers);

        layers = filter(layers, (l) => l.id != layer.id);

        config.layers = layers;

        if (getters.workOrderId) {
            await Layer.deleteWorkOrderMapLayer(getters.workOrderId, layer)
        } else if (getters.user && getters.id) {
            await Layer.delete(getters.user, getters.id, layer);
        } else {
            throw new Error('Invalid Layer Remove');
        }

        await commit('UPDATE_MAP', config);
        events.$emit('map:updated', config);
    },
    async addLayer({
        getters,
        commit
    }, payload) {
        let layers = cloneDeep(getters.layers);
        let config = cloneDeep(getters.config);

        // create the layer
        payload.visible = true;
        let layer = null
        if (getters.workOrderId) {
            layer = await Layer.createWorkOrderMapLayer(getters.workOrderId, payload)
        } else if (getters.user && getters.id) {
            layer = await Layer.create(getters.user, getters.id, payload)
        }

        if (!layer) {
            throw new Error('Invalid Layer Add');
        }

        // add layer to configuration
        layers.unshift(layer)
        config.layers = layers

        // update map with new configuration
        await commit('UPDATE_MAP', config);
        events.$emit('map:updated', config);

        return layer
    },
    // reorder layers
    async reorderLayers({ getters, commit }, layerIds) {

        // update locally
        let config = cloneDeep(getters.config);

        // sort and update the order
        config.layers = config.layers
            .sort( (a, b) => layerIds.indexOf(a.id) < layerIds.indexOf(b.id) ? -1: 1 )
            .map( (layer, i) => Object.assign( layer, {order: i}) )

        // update map with new layer order
        await commit('UPDATE_MAP', config);

        // update remotely
        if (getters.workOrderId) {
            return await Layer.reorderWorkOrderMapLayer(getters.workOrderId, layerIds)
        } else if (getters.user && getters.id) {
            return await Layer.reorder(getters.user, getters.id, layerIds);
        } else {
            throw new Error('Invalid Layer Reorder.');
        }
    },
    updateLayer({
        commit
    }, payload) {
        return commit('UPDATE_LAYER', payload);
    },
    clearLayers({commit}) {
        return commit('CLEAR_LAYERS');
    },
    addAnnotation({
        dispatch
    }, {layer, annotation, addToBeginning = false}) {
        let annotations = !isEmpty(layer.annotations) ? cloneDeep(layer.annotations) : [];

        addToBeginning ? annotations.unshift(annotation) : annotations.push(annotation);

        dispatch('updateLayer', {
            ...layer,
            annotations: annotations
        });
    },
    updateAnnotation({
        dispatch
    }, {layer, annotation, index}) {
        let annotations = cloneDeep(layer.annotations);

        annotations.splice(index, 1, annotation);
        const geoJson = MapService.geoJsonFromLayerAnnotations(layer, annotations)

        dispatch('updateLayer', {
            ...layer,
            annotations: annotations,
            geoJson: geoJson
        });
    },
    removeAnnotation({
        dispatch
    }, {layer, index}) {
        let updatedLayer = cloneDeep(layer);
        let annotations = updatedLayer.annotations;
        annotations.splice(index, 1);

        annotations.forEach((annotation, index) => {
            annotation.order = index + 1;
        });

        const geoJson = MapService.geoJsonFromLayerAnnotations(layer, annotations)

        dispatch('updateLayer', {
            ...updatedLayer,
            geoJson
        });
    },
    setPublic({
        commit
    }, value) {
        commit('SET_PUBLIC_MODE', value);
    },
    setStyleReady({
        commit
    }, value) {
        commit('SET_STYLE_READY', value);
    },
    setChangingProvider({
        commit
    }, value) {
        commit('SET_CHANGING_PROVIDER', value);
    },
};

// mutations
const mutations = {
    SET_MAP(state, config) {
        state.config = config;
        state.loaded = true;
    },
    SET_STYLE_READY(state, value) {
        state.styleReady = value;
    },
    CLEAR_MAP(state) {
        state.config = {};
        state.loaded = false;
    },
    UPDATE_MAP(state, config) {
        state.config = config;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_MAP_CENTER(state, center) {
        state.mapCenter = center;
    },
    CLEAR_MAP_CENTER(state) {
      state.mapCenter = null;
    },
    SET_MAP_BOUNDS(state, bounds) {
        state.mapBounds = bounds;
    },
    CLEAR_MAP_BOUNDS(state) {
      state.mapBounds = null;
    },
    SET_LAYERS(state, layers) {
        state.config.layers = layers
    },
    UPDATE_LAYER(state, payload) {
        let layers = state.config.layers;

        replace(layers, {
            id: payload.id
        }, payload);

        return find(layers, (r) => r.id == payload.id);
    },
    CLEAR_LAYERS(state) {
        state.config.layers = {};
    },
    SET_PUBLIC_MODE(state, value) {
        state.public = value;
    },
    SET_CHANGING_PROVIDER(state, value) {
        state.changingProvider = value;
    }
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
};
