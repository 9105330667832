<template>
    <div class="p-2" @mouseover="onMouseOver" @click.stop="onClick">
        <a v-if="url" :href="url" target="_blank" class="feature-reference-url" />
        <div class="feature-icon-wrapper-outer mb-2">
            <div class="feature-icon-wrapper-inner" :class="'circle-rating-' + rating">
                <div v-if="rating" class="feature-rating">
                    {{ rating }}
                    <span>&#47;10</span>
                </div>
                <div v-else class="feature-rating">
                    <span class="v-align-middle">no rating</span>
                </div>
            </div>
        </div>
        <div class="feature-details-wrapper">
            <h6 class="feature-header">{{ name }}</h6>
            <p v-if="address" class="feature-address">{{ address }}, {{ city }}, {{ state }}, {{ zip }}</p>
            <p class="feature-misc-details">
                {{ level }} School
                <span>, {{ grades }} |</span>
                {{ num_students }}
                <span>students</span>
            </p>
            <div class="feature-last-row">
                <p class="feature-distance">Distance: {{ distance }} miles</p>
                <img class="great-schools-logo" src="/images/great-schools-logo.png" alt="Great Schools" />
            </div>
        </div>
    </div>
</template>

<script>
import { get, debounce } from 'utils/lodash';
import { distance } from '@/utils/helpers';
import { mapGetters } from 'vuex';
export default {
    props: {
        record: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters('map', ['latitude', 'longitude']),
        url() {
            return get(this.record, 'properties.website');
        },
        rating() {
            return get(this.record, 'properties.rating');
        },
        name() {
            return get(this.record, 'properties.name');
        },
        distance() {
            return distance(
                this.latitude,
                this.longitude,
                get(this.record, 'geometry.coordinates.1'),
                get(this.record, 'geometry.coordinates.0')
            );
        },
        level() {
            return get(this.record, 'properties.level');
        },
        grades() {
            return get(this.record, 'properties.grades');
        },
        num_students() {
            return get(this.record, 'properties.enrollment');
        },
        address() {
            return get(this.record, 'properties.address', null);
        },
        city() {
            return get(this.record, 'properties.city', null);
        },
        state() {
            return get(this.record, 'properties.state', null);
        },
        zip() {
            return get(this.record, 'properties.zip', null);
        },
    },
    methods: {
        onMouseOver: debounce(function () {
            this.$emit('hover', this.record);
        }, 250),
        onClick() {
            this.$emit('click', this.record);
        },
    },
};
</script>
