<template>
    <div class="relocity-schools template">
        <div class="main-content">
            <header class="p-2" ref="schoolInfoPanel">
                <div class="font-3 primary-text-color">School</div>
                <div class="font-1 primary-text-color">
                    {{ rowProperty('properties.ie-schools-details-school-name', 'N/A') }}
                </div>
                <div class="font-3 secondary-text-color">
                    {{ rowProperty('properties.ie-schools-details-address', 'N/A') }}
                </div>
            </header>

            <div class="hbhw h-48 force-no-mb">
                <div class="h-24 force-no-mb"></div>
                <!-- overlay starts-->
                <div class="over relative bg-transparent m-4">
                    <div ref="mapContainer">
                        <map-image
                            class="h-44 w-full rounded-lg"
                            :longitude="coordinates[0]"
                            :latitude="coordinates[1]"
                            :zoom="13"
                            :height="height"
                            :width="width"
                            makiIcon="school"
                            iconColor="23B899"
                        />
                    </div>
                </div>
                <!-- overlay ends -->
            </div>
            <div class="details bg-black">
                <div class="flex flex-row justify-center mb-8">
                    <div class="flex flex-row w-32 justify-evenly">
                        <a class="icon-wrapper" :href="contactEmail" v-if="contactEmail">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-size"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.Ï997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                        </a>
                        <a class="icon-wrapper" :href="contactPhone" v-if="contactPhone">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-size"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                                />
                            </svg>
                        </a>
                        <a class="icon-wrapper" :href="website" v-if="website">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-size"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>
                    </div>
                </div>

                <!-- grid start -->
                <div class="grid grid-cols-2 gap-x-40">
                    <!-- row 1 -->
                    <hr class="border-gray-700 col-span-2" />

                    <!-- row 2 -->
                    <div class="col-span-2" v-if="category">
                        <div class="font-3 secondary-text-color">Category</div>
                        <div class="font-2 accent-text-color">
                            {{ category }}
                        </div>
                    </div>

                    <!-- row 3 -->
                    <div v-if="gender">
                        <div class="font-3 secondary-text-color">Gender</div>
                        <div class="font-2 accent-text-color">{{ gender }}</div>
                    </div>

                    <div v-if="pupils">
                        <div class="font-3 secondary-text-color">Pupils</div>
                        <div class="font-2 accent-text-color">
                            {{ pupils }}
                        </div>
                    </div>

                    <!-- row 4 -->
                    <div v-if="religion">
                        <div class="font-3 secondary-text-color">Religion</div>
                        <div class="font-2 accent-text-color">
                            {{ religion }}
                        </div>
                    </div>

                    <div v-if="feePaying">
                        <div class="font-3 secondary-text-color">Fee Paying</div>
                        <div class="font-2 accent-text-color">
                            {{ feePaying }}
                        </div>
                    </div>

                    <!-- row 5 -->
                    <hr class="border-gray-700 col-span-2" />

                    <!-- row 6 -->
                    <div class="col-span-2" v-if="contactPhone">
                        <div class="font-3 secondary-text-color">Phone</div>
                        <a class="font-2 accent-text-color underline" :href="contactPhone">
                            {{ e164Phone }}
                        </a>
                    </div>

                    <!-- row 7 -->
                    <div class="col-span-2" v-if="contactEmail">
                        <div class="font-3 secondary-text-color">Email</div>
                        <div class="font-2 accent-text-color">
                            {{ rowProperty('properties.ie-schools-details-contact-email') }}
                        </div>
                    </div>

                    <!-- col span 8 -->
                    <div class="col-span-2" v-if="website">
                        <div class="font-3 secondary-text-color">Website</div>
                        <a
                            :href="website"
                            class="font-2 accent-text-color underline"
                            style="font-style: italic"
                            target="blank"
                        >
                            {{ rowProperty('properties.ie-schools-details-website', 'N/A') }}
                        </a>
                    </div>
                </div>
                <!-- grid end -->
            </div>
        </div>
    </div>
</template>

<script>
import { layerDetails } from 'mixins';
import MapImage from '@/components/ui/images/map-image';

export default {
    mixins: [layerDetails],
    components: {
        MapImage,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        showMap: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        width: 342,
        height: 176,
        navHeight: null,
        schoolInfoPanelSize: null,
        mapContainerTop: 0,
    }),
    mounted() {
        const box = this.$refs.mapContainer;
        const boxSize = box.getBoundingClientRect();
        this.width = Math.min(Math.trunc(boxSize.width), 1280);
        this.height = 176;
    },
    computed: {
        row() {
            return this.record;
        },
        props() {
            return this.record.properties;
        },
        coordinates() {
            return this.row.geometry.coordinates;
        },
        category() {
            return this.getPropOrFalse('ie-schools-amenities-category');
        },
        gender() {
            return this.getPropOrFalse('ie-schools-amenities-gender');
        },
        pupils() {
            return this.getPropOrFalse('ie-schools-details-enrollment');
        },
        religion() {
            return this.getPropOrFalse('ie-schools-amenities-religion');
        },
        feePaying() {
            return this.getPropOrFalse('ie-schools-amenities-fee-paying');
        },
        contactEmail() {
            const email = this.getPropOrFalse('ie-schools-details-contact-email');
            return email ? `mailto:${email}` : false;
        },
        e164Phone() {
            const e164 = String(this.getPropOrFalse('ie-schools-details-contact-phone'));
            if (e164 !== 'false') {
                return e164.startsWith('+') ? e164 : `+${e164}`;
            }

            return false;
        },
        contactPhone() {
            return this.e164Phone ? `tel:${this.e164Phone}` : false;
        },
        website() {
            const website = this.getPropOrFalse('ie-schools-details-website');
            if (website) {
                return website.startsWith('http') ? website : `https://${website}`;
            }
            return false;
        },
    },
    methods: {
        getPropOrFalse(property) {
            const propertyPath = 'properties.' + property;
            return this.rowProperty(propertyPath, null) === null ? false : this.rowProperty(propertyPath);
        },
    },
};
</script>
<style lang="scss">
.relocity-schools.template {
    /* Header */
    header {
        min-height: 40px;
        padding: 20px;
    }

    .force-no-mb {
        margin-bottom: 0px !important;
    }

    /* Details */
    .details {
        padding: 20px;
        cursor: default !important;
    }

    .icon-wrapper {
        border-radius: 9999px;
        height: 1.75rem;
        width: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #23b899;
    }

    .icon-size {
        height: 1rem;
        width: 1rem;
    }

    .font-1 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 1.2rem;
    }

    .font-2 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
    }

    .font-3 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .primary-text-color {
        color: #505152;
    }

    .secondary-text-color {
        color: #9b9b9b;
    }

    .accent-text-color {
        color: #fff;
    }

    .over {
        top: -45%;
    }

    // half black half white
    .hbhw {
        background-image: linear-gradient(to top, #000000 0%, #000000 50%, #ffffff 50%, #ffffff 100%);
    }
}
</style>
