<template>
    <img :src="imageSource" />
</template>

<script>
export default {
    props: {
        longitude: {
            type: Number,
            required: true,
        },
        latitude: {
            type: Number,
            required: true,
        },
        zoom: {
            type: Number,
            default: 5,
        },
        height: {
            type: Number,
            default: 100,
        },
        width: {
            type: Number,
            default: 100,
        },
        makiIcon: {
            type: String,
            default: null,
        },
        iconColor: {
            type: String,
            default: null,
        },
        iconSize: {
            type: String,
            default: 'l',
        },
    },
    computed: {
        overlay() {
            return this.makiIcon === null
                ? ''
                : `/pin-${this.iconSize}-${this.makiIcon}+${this.iconColor}(${this.longitude},${this.latitude})`;
        },
        imageSource() {
            return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static${this.overlay}/${this.longitude},${this.latitude},${this.zoom},0,0/${this.width}x${this.height}@2x?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`;
        },
    },
};
</script>
