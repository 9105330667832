<template>
    <div class="w-auto pointer-events-none flex justify-center text-sm z-10">
        <div
            v-if="totalCount && displayCount < totalCount"
            class="w-48 bg-black bg-opacity-75 text-white p-2 rounded text-center pointer-events-none"
        >
            {{ displayCount }} of {{ totalCount }} displayed
        </div>
        <div v-else class="w-32 bg-black bg-opacity-75 text-white p-2 rounded text-center pointer-events-none">
            {{ displayCount }} displayed
        </div>
    </div>
</template>
<script>
export default {
    props: {
        displayCount: {
            type: Number,
            required: true,
        },
        totalCount: {
            type: Number,
        },
    },
};
</script>
