import http from 'services/axios';

class Records {
    static search({ category, query }) {
        return http.get(process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL + `/api/v1/records/${category}/search`, {
            params: query,
        });
    }
    static getUrlData(url) {
        return http.get(url);
    }
    static get({ category, id }) {
        return http.get(process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL + `/api/v1/records/${category}/${id}`);
    }

    static getGeoJSON({ category, id }) {
        return http.get(process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL + `/api/v1/records/${category}/${id}`, {
            params: {
                geojson: 1,
            },
        });
    }
}

export default Records;
