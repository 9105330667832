import axios from 'axios';
import store from 'store';
import router from 'router';
import qs from 'qs';

const http = axios.create({
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json',
        },
    },
});

// serialize arrays as url-encoded querystring, rather than json objects
http.defaults.paramsSerializer = (p) => qs.stringify(p, { arrayFormat: 'brackets' });

http.interceptors.request.use(function (config) {
    if (config.url.indexOf(process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL) > -1) {
        config.headers['X-Authorization'] = process.env.VUE_APP_RELOCITY_DATA_CONTENT_API_KEY;
        config.headers.Authorization = null;
    }

    let token = localStorage.relocity_access_token;
    if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});
http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.setItem(
                'next-route',
                JSON.stringify({ name: router.app._route.name, params: router.app._route.params })
            );
            router.replace({ name: 'login' });
            return;
        } else if (error.response.status === 403) {
            store.commit('setErrorMessages', '403 Forbidden');
            store.dispatch('alerts/error', '403 Forbidden');
        } else {
            var errorMessages = [];

            if (Array.isArray(error.response.data.errors)) {
                errorMessages = error.response.data.errors.map(function (errorObj) {
                    return errorObj.detail;
                });
            } else {
                for (var property in error.response.data.errors) {
                    if (error.response.data.errors.hasOwnProperty(property)) {
                        errorMessages = errorMessages.concat(error.response.data.errors[property]);
                    }
                }
            }
            store.dispatch('alerts/error', errorMessages.join('<br />'));
        }

        return Promise.reject(error.response);
    }
);

export default http;
