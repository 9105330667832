import Area from 'models/Area';
import {isEmpty, includes} from 'utils/lodash';

const state = {
  areas: []
};

// getters
const getters = {
  areas: state => state.areas,
  roleHasAreas: (state, getters, rootState, rootGetters) => roleId => {
    let role = rootGetters['roles/findById'](roleId);

    if (isEmpty(role)) {
      return false;
    }

    return includes([
      'Area Ops Manager', 'Host'
    ], role.name);
  }
};

const actions = {
  async all({commit}) {
    let response = await Area.all();

    let data = response.data.data;

    await commit('SET_AREAS', data);

    return data;
  }
};

// mutations
const mutations = {
  SET_AREAS(state, rows) {
    state.areas = rows;
  },
  CLEAR_AREAS(state) {
    state.areas = [];
  }
};

export default {
  namespaced : true,
  state,
  getters,
  actions,
  mutations
};
